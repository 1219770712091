import React from 'react'
import './style.css'

function TableSpinner() {
    return (
        <div  className="spin-pos">
            <div className="atom-spinner">
                <div className="spinner-inner">
                    <div className="spinner-line"></div>
                    <div className="spinner-line"></div>
                    <div className="spinner-line"></div>
                    <div className="spinner-circle">
                    &#9679;
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableSpinner
