import React, { useState, useContext, useEffect } from "react";
import { Col, FormGroup, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { AppContext } from "../../../contexts/AppContext";
import { withRouter } from "react-router-dom";
import Axios from "axios";

import ObjectInfo from "./ObjectInfo";
import CustomerInfo from "./CustomerInfo";
import ClientObjectTable from "./ClientObjectTable";
import ClientPrivateSelfUsed from "./ClientPrivateSelfUsed";
import Page from "../../common/Page";
import Totals from "./Total";
import { access } from "fs";
 
const Calculation = withRouter(({ history, match }) => {
  const { dispatch, swalAlert, clientDetails, isCalculate, translation, locale, contWrapper, stickyHeader, noObjectData,computeTotal, clientId, newObjAdded, loadingObjectData } = useContext(
    AppContext
  );

    const [modalObject, setModalObject] = useState(false);
    const [objProgLi, setobjProgLi] =  useState(match.params.progress == "All"?'All':match.params.progress)
    const [progLength, setProgLength] = useState('')
    
    

    useEffect(() => {
      Axios.get(`/api/clients/${match.params.id}`)
        .then(res => {
          dispatch({ clientDetails: res.data[0] });
        })
        .catch(err => {
          console.log(err);
        });
      if (isCalculate) {
        dispatch({ isCalculate: false });
      }
    }, [clientDetails.partner_nr]);

    useEffect(() => {
      const fetchObjectData = async(newObjAdded) => {
          // dispatch({loadingObjectData:loadingObjectData == true ?'':true})
          await Axios.get(`/api/objectdata/${match.params.id}/${match.params.progress}`)
              .then((res) => {
                  dispatch({ObjektangabenList:res.data})
                  dispatch({noObjectData:res.data.length <= 0 ? true:false})
                  computeTotal(res.data)
                  dispatch({loadingObjectData:false})
              })
              .catch((err) => {
                  console.log(err)
              })
      }
      
      if(newObjAdded == true){
        fetchObjectData(newObjAdded)
        setobjProgLi('in progress')
        history.push(`/calculate/${match.params.id}/in progress`)
      }else{
        fetchObjectData()
      }

      Axios.get(`/api/objectdata/${match.params.id}`)
        .then(async(res) => {
          
          var all   = res.data.filter(row => row.all)[0].all
          var inPro = res.data.filter(row => row.progress == 'In progress')[0]
          var offer = res.data.filter(row => row.progress == 'Offered')[0]
          var acc   = res.data.filter(row => row.progress == 'Accepted')[0]
          var dis   = res.data.filter(row => row.progress == 'Dismissed')[0]
          var ns    = res.data.filter(row => row.progress == '')[0]

            setProgLength(
              {
                all : all   == undefined ? 0 : all,
                ip  : inPro == undefined ? 0 : inPro.no_progress, 
                of  : offer == undefined ? 0 : offer.no_progress, 
                ac  : acc   == undefined ? 0 : acc.no_progress, 
                dis : dis   == undefined ? 0 : dis.no_progress,
                ns  : ns    == undefined ? 0 : ns.no_progress 
              }
            )
        })
        .catch((err) => {
            console.log(err)
        })
        
      dispatch({objCurTab:objProgLi})
    }, [noObjectData, match.params.progress, loadingObjectData, newObjAdded])

    const changeObjList = (objList) => {
      dispatch({ObjektangabenList:[]})
      history.push(`/calculate/${match.params.id}/${objList}`)
      setobjProgLi(objList)
      dispatch({newObjAdded:false})
      dispatch({loadingObjectData:true})
      dispatch({objCurTab:objProgLi})
    }

  return (
      <>
        <Page
          title={!isCalculate ? translation.Objekt[locale] : translation.calcu[locale]}
          description={translation.client[locale]}
          icon={!isCalculate ? "fas fa-building" : "fas fa-calculator"}
        >
          {swalAlert}
            <div>
              <FormGroup row>
                <Col sm="6"></Col>
                <Col sm="6" className="text-right stickyHeader">
                  <Button
                    className={stickyHeader?"ml-4 back-to-client back-btn shadow custom stickybtn":"ml-4 back-to-client back-btn shadow custom"}
                    onClick={() =>
                      !isCalculate
                        ? history.push(`/client`)
                        : dispatch({ isCalculate: false })
                    }
                  >
                    <i className="fas fa-chevron-left"></i>
                  </Button>
                </Col>
              </FormGroup>
            </div>

            <div className={contWrapper?'cont-wrapper':''}>

              {isCalculate ? (
                <>
                  <div className="mt-4 mb-1">
                    <h3 className="headerName">{translation.cus_dat[locale]}</h3>
                  </div>
                  <ClientPrivateSelfUsed id={match.params.id} />
                </>
              ) : (
                <>
                  <div className="row ">
                    <Col sm="2">
                      <div className="obj-stat-wrapper">
                        <ul>
                          <li
                            className={objProgLi == "All"?'obj-list-active all':''} 
                            onClick={ () => { changeObjList('All') } }>
                            {translation.all[locale]}
                            <span>{progLength.all}</span>
                          </li>

                          <li className={objProgLi == "in progress"?'obj-list-active in-progress':''} 
                            onClick={ () => { changeObjList('in progress') } }>
                            {translation.in_prog[locale]}
                            <span>{progLength.ip}</span>
                          </li>

                          <li className={objProgLi == "offered"?'obj-list-active offered':''}
                            onClick={ () => { changeObjList('offered') } }>
                            {translation.offered[locale]}
                            <span>{progLength.of}</span>
                          </li>

                          <li className={objProgLi == "accepted"?'obj-list-active accepted':''}
                            onClick={ () => { changeObjList('accepted') } }>
                            {translation.accepted[locale]}
                            <span>{progLength.ac}</span>
                          </li>

                          <li className={objProgLi == "dismissed"?'obj-list-active dismissed':''}
                            onClick={ () => { changeObjList('dismissed') } }>
                            {translation.dismissed[locale]}
                            <span>{progLength.dis}</span>
                          </li>

                          
                        </ul>
                      </div>
                    </Col>
                    <Col sm="10">
                      <div className="page-cform shadow">
                        <ClientObjectTable noObjectData={noObjectData}/>
                      </div>
                    </Col>
                  </div>
                </>
              )}
            </div>

          <Modal
            size="lg"
            isOpen={modalObject}
            toggle={() => setModalObject(!modalObject)}
          >
            <ModalHeader toggle={() => setModalObject(!modalObject)}>
              <div>
                <h3>{translation.obj_dat[locale]}</h3>
              </div>
            </ModalHeader>
            <ModalBody>
              <ObjectInfo />
            </ModalBody>
          </Modal>
        </Page>
      
      </>
  );
});

export default Calculation;
