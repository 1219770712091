import React from 'react'

function ProcessingSpinner() {
    return (
        <>
            <div className="half-circle-spinner">
                <div className="circle circle-1"></div>
                <div className="circle circle-2"></div>
            </div>  
        </>
    )
}

export default ProcessingSpinner
