import React, { useEffect, useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import {AppContext} from './../../contexts/AppContext'
import { 

   Container, 
   Row, 
   Col,  
  
} from 'reactstrap'

// Common
import HeadBanner from '../common/HeadBanner'

const Report = ({children, title, description, icon}) => {
   var {dispatch, stickyHeader} = useContext(AppContext)
   const trans = useSpring({ 
      to: {
         opacity: 1, 
         marginLeft: 0
      }, 

      from: { 
         opacity: 0, 
         marginLeft: -500 
      }, 
      delay: 100,
      
   })

   function isBottom(el){
      return el.getBoundingClientRect().bottom <= window.innerHeight;
   }

   // useEffect(() => {
   //    window.onscroll = function() {
   //       if (window.pageYOffset > 19) {
   //          document.getElementById('header-page-title').style.marginLeft = 0
   //          document.getElementById('header-page-title').style.marginRight = 0
   //          document.getElementById('header-page-title').style.width = 88+'%'
   //          document.getElementById('header-page-title').style.position = 'fixed'
   //          document.getElementById('header-page-title').style.marginTop = -20+'px'
   //          document.getElementById('header-page-title').style.zIndex = 1
   //          document.getElementById('const-wrapper').addClass = 'px'
   //       }
   //       if(window.pageYOffset < 19){
   //          document.getElementById('header-page-title').style.marginLeft = 15+'px'
   //          document.getElementById('header-page-title').style.marginRight = 15+'px'
   //          document.getElementById('header-page-title').style.width = '100%'
   //          document.getElementById('header-page-title').style.position = 'unset'
   //          document.getElementById('header-page-title').style.marginTop = 'unset'
   //          document.getElementById('header-page-title').style.zIndex = 'unset'
   //          document.getElementById('const-wrapper').marginTop = 0

   //       }
   //     }
       
   // }, [])
   
   return (
   
      <animated.div style={trans}>


         <Container className="mb-3" fluid>
            <Row  className="page-title" >
                  <div className={stickyHeader?'header-page-title stickyHeader':'header-page-title' }>
                     <HeadBanner title={title}  withButton={false} />
                  </div>
               <Col>
               </Col>
            </Row>
           
           {children}
            
         </Container>
      </animated.div>
     
   )
}

export default Report

