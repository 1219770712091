import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";

const SideNav = withRouter(
  ({ history, sideNavIsOpen, showLinkTitle, ...rest }) => {
    const { dispatch, navStat, translation, locale } = useContext(AppContext);
    const goTo = to => {
      history.push(to);
    };

    return (
      <div>
        <aside className={navStat ? "sidenav active-sn" : "sidenav inactive-sn"}>
          <ul style={{ textAlign: "center" }}>
            <li
              className={
                history.location.pathname === "/"
                  ? "px-3 py-2 border-bottom border-top d-flex active-li"
                  : "px-3 py-2 border-bottom border-top d-flex"
              }
              onClick={() => goTo("/")}
            >
              <div style={{ width: "40px" }}>
                <span className="icon-link">
                  <i className="fas fa-list fa-lg"></i>
                </span>
              </div>{" "}
              <span className={navStat?'navName':'noNavName'}>{showLinkTitle && navStat ? "Dashboard" : ""}</span>
            </li>
            <li
              className={
                history.location.pathname === "/client" ||
                !!history.location.pathname.match("calculate")
                  ? "px-3 py-2 border-bottom border-top d-flex active-li"
                  : "px-3 py-2 border-bottom border-top d-flex"
              }
              onClick={() => goTo("/client")}
            >
              <div style={{ width: "40px" }}>
                <span className="icon-link">
                  <i className="fas fa-user-tie"></i>
                </span>
              </div>{" "}
              <span className={navStat?'navName':'noNavName'}>{showLinkTitle && navStat ? translation.client[locale] : ""}</span>
            </li>
          </ul>
        </aside>
      </div>
    );
  }
);


export default SideNav;
