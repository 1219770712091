import React, {useContext} from 'react'
import {Button} from 'reactstrap'

import {AppContext} from '../../../../contexts/AppContext'

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

let customStyle = {border: [false, false, false, false], color:'#404243', fontSize: 12, margin: [0, 6, 0, 6]}
const ObjectCalculation = () => {
    const {dispatch, KundeGui}= useContext(AppContext)
    const printOjbCalc = () => {

        window.open(window.print())
        
    //     var docDefinition = {
    //         content:[
    //             {text: 'Tables', style: 'header'},
    //             {
    //                 columns: [
    //                     {
    //                         table: {
    //                             body: [
    //                                 [
    //                                     {
    //                                         text: 'Businessparter-Nr.:',
    //                                         ...customStyle
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Objektart:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Nutzung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Verkehrswert:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Maximale Kreditierung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Kaufpreis:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'IST-Eigenmittel:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'IST-Kreditierung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Anteil 2. Hypothek:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Kalk. Zinsbelastung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Soll-Amortisation:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Nebenkosten:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Baurechtszinsen:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Total Belastung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Einkommen:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: '%-tuale Belastung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
                                    
    //                             ]
    //                         },
    //                     },
    //                     {
    //                         table: {
    //                             body: [
    //                                 [
    //                                     {
    //                                         text: 'Businessparter-Nr.:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Objektart:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Nutzung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Verkehrswert:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Maximale Kreditierung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Kaufpreis:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'IST-Eigenmittel:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'IST-Kreditierung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Anteil 2. Hypothek:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Kalk. Zinsbelastung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Soll-Amortisation:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Nebenkosten:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Baurechtszinsen:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Total Belastung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Einkommen:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: '%-tuale Belastung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
                                    
    //                             ]
    //                         },
    //                     },
    //                     {
    //                         table: {
    //                             body: [
    //                                 [
    //                                     {
    //                                         text: 'Businessparter-Nr.:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Objektart:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Nutzung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Verkehrswert:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Maximale Kreditierung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Kaufpreis:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'IST-Eigenmittel:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'IST-Kreditierung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Anteil 2. Hypothek:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Kalk. Zinsbelastung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Soll-Amortisation:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Nebenkosten:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Baurechtszinsen:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Total Belastung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: 'Einkommen:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
    //                                 [
    //                                     {
    //                                         text: '%-tuale Belastung:',
    //                                         ...customStyle,
    //                                     },
    //                                 ],
                                    
    //                             ]
    //                         },
    //                     }
    //                 ]
    //             },
    //         ]
    //     }

    //     const pdfDoc = pdfMake.createPdf(docDefinition).open()
        // console.log(id)
    }

    return (
        <>
            <a href="#" onClick={() => { printOjbCalc() }}><i className="fa fa-print obj-list" ></i> </a>
        </>
    )
}

export default ObjectCalculation
