import React from 'react'
import { Spinner } from 'reactstrap'
import Avatar from './Avatar'
import './style.css'

const Loading = () => {
   return (
      <div className="loading-banner">
         {/* <Avatar /> */}
         <div className="mb-2" >
            {/* <Spinner style={{ width: '1rem', height: '1rem' }} type="grow" color="danger" className="ml-2" />
            <Spinner style={{ width: '1rem', height: '1rem' }} type="grow" color="danger" className="ml-2" />
            <Spinner style={{ width: '1rem', height: '1rem' }} type="grow" color="danger" className="ml-2" /> */}

               <div className="atom-spinner">
                  <div className="spinner-inner">
                     <div className="spinner-line"></div>
                     <div className="spinner-line"></div>
                     <div className="spinner-line"></div>
                     <div className="spinner-circle">
                        &#9679;
                     </div>
                  </div>
               </div>
         </div>
         
         Loading Resource
      </div>
   )
}

export default Loading