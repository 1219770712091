import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
// import * as serviceWorker from './serviceWorker'
import { AuthProvider } from './contexts/AuthContext'
import { AppProvider } from './contexts/AppContext';

ReactDOM.render(
   <AppProvider>
      <AuthProvider>
         <App /> 
      </AuthProvider>
   </AppProvider> ,
   document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
