import React, { useState, useContext } from 'react';

import {
  Table,
  Col,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import InsertClient from './InsertClient';
import ClientList from './ClientList';
import Page from '../common/Page';
import { AppContext } from '../../contexts/AppContext';
import CustomerInfo from './CustomerInfo';

const Finance = withRouter(({ history }) => {
  const {
    dispatch,
    openModalClient,
    modalClient,
    translation,
    locale,
  } = useContext(AppContext);

  return (
    <Page
      title={translation.client[locale]}
      description={translation.masterList[locale]}
      icon="fas fa-user-tie"
    >
      <div className="custom-cont-fluid">
        <ClientList />
      </div>

      <Modal
        size="lg"
        isOpen={modalClient}
        toggle={() => dispatch({ modalClient: !modalClient })}
      >
        <ModalHeader toggle={() => dispatch({ modalClient: !modalClient })}>
          <div>
            <h3>{translation.cli_info[locale]}</h3>
          </div>
        </ModalHeader>

        <ModalBody>
          {/* <InsertClient /> */}
          <CustomerInfo />
        </ModalBody>
      </Modal>
    </Page>
  );
});

export default Finance;
