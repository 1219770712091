import React, { useEffect, useReducer, useState } from 'react'
import Axios from 'axios'
import SweetAlert from 'react-bootstrap-sweetalert';
import numeral from 'numeral'
import translation from './../translationFile/data.json'
export const AppContext = React.createContext()

const AuthReducer = (state, action) => ({ ...state, ...action })

const clientState = {
    KundennameI:'', 
    KundennameII:'',
    JahrgangI:'',
    JahrgangII:'',
    Adresse:'',
    PLZWohnort:'',
    PartnerNr:'',
    FixkomKreI:0, 
    FixkomKreII:0, 
    CalcInputVarKomI:0, // sub Total
        inputVarKommenCol1:0,
        inputPercVarKommenCol1:0,
    CalcInputVarKomII:0, // sub Total
        inputVarKommenCol2:0,
        inputPercVarKommenCol2:0,
    NebkomKNI:0,
    NebkomKNII:0,
    bersLie:0,
    TotalEinkommen:0, //Total
    bersLieMinus:0,
    BelLeasings:0,
    Alimente:0,
    TotBelastung:0, //Total
    VerEinkom:0, //Total
    VerEinkomcal:0,
}
const objectInfoState = { 
    Objektart:'Einfamilienhaus', 
    Nutzung:'selbstgenutzt', 
    Verkehrswert:'', 
    Adresse:'', 
    PLZ:'', 
    Ort:'', 
    Baujahr:'', 
    baurecht:'Nien', 
    Baurechtszins:'',
    Zentrumslage:0, 
    Luxusobjekt:0,
    DauerBaurecht:'',
    progress:'In progress',
    status:'Active'
 }
const KundeGuiState = {
    calcName:'',
    BsnespartNr:0,
    // Verkehrswert:0,
        VerkePercent:0,
    MaxKredit:0,
    Kaufpreis:0,
        ISTKreditRi:0,
    ISTEigenmittel:0,
    ISTKredit:0,
        ISTKreditPercentage:0,
    Anteil2Hypothek:0,
    KalkZinsbel:0,
        KalkZinsbelPercent:5,
    SollAmortis:0,
        SollAmortisDividend:0,
    Nebenkosten:0,
        NebenkostenDivident:0,
    Baurechtszinsen:0,
    TotalBelastung:0,
    Einkommen:0,
    percentualeBel:0,
    percentualeBelcal:0,
}
// const plzOrtState = {
//     PLZ:'',
//     Ort:''
// }

const dashboardState = {
    // count by length
    objCount:0, 
    objLength:0, 
    objAccepted:0, 
    objOpen:0, 
    objActive:0,
    objInActive:0,
    objOffer:0,
    objDismissed:0,
    // count by Verkerswert 
    objActVerk:0,
    objInActVerk:0,

    objOpenVerk:0, 
    objOfferVerk:0,
    objAcceptedVerk:0, 
    objDismissedVerk:0,

    inProgJan:0,
    inProgFeb:0,
    inProgMar:0,
    inProgApr:0,
    inProgMay:0,
    inProgJun:0,
    inProgJul:0,
    inProgAug:0,
    inProgSep:0,
    inProgOct:0,
    inProgNov:0,
    inProgDec:0,
}

const initialState = {

    translation,
    locale:(localStorage.getItem('locale') === null || localStorage.getItem('locale') === undefined)? 'de' : localStorage.getItem('locale'),
    clientDetails:clientState,
    clientData:[],
    clientId:'',
    selectedRowsData:[],
    loadingClientData:true,

    objectInfo:objectInfoState,
    ObjektangabenList:[],
    objectSumTotal:0,
    isInsertObject:true,
    objectId:'',
    loadingObjectData:true,
    noObjectData:false,

    KundeGui:KundeGuiState,
    isInsert:true,
    
    kundeGuiId:'',

    navStat:true,
    isCalculate:false,
    modalClient:false,
    calData:false,

    isSave:true,
    HSRegion:[],

    // plzOrt:plzOrtState,
    isCalculate:false,

    objectId:0,
    objectIdForCalc:0,
    objectVerkehrswertForCalc:0,
    loadingObjectCalcData:true,

    inputfaktorenList:[],

    saveClientErr:false,
    client_id:'',

    objCalcDataList:[],
    dashboard:dashboardState,

    stickyHeader:false,
    contWrapper:false,
    newObjAdded:false,

    objCurTab:'All',
    isSaveClientDetails:false,
    
}


export const AppProvider = (props)=> {
    const [contextState, dispatch] = useReducer(AuthReducer, initialState)
    var conTrans = contextState.translation
    var conLoc = contextState.locale

    useEffect(() => {
        const fetchCleintData = async() => {

            await Axios.get('/api/clients')
                .then(async (res, ) => {
                    await dispatch({clientData:res.data})
                    // console.log(res.data)
                    await dispatch({loadingClientData:false})
                    
                })
                .catch((err) => {
                    console.log(err)
                })
            
        }
        fetchCleintData()
    }, [contextState.isInsert])
    
    useEffect(() => {
        window.onscroll = function() {
            if (window.pageYOffset > 10) {
                dispatch({stickyHeader:true})
                dispatch({contWrapper:true})
            }else{
                dispatch({stickyHeader:false})
                dispatch({contWrapper:false})
            }
         }
     }, [])

    const resetClientModal = () => {
        dispatch({
            clientDetails:{
                ...contextState.clientDetails,
                kundenname:'',
                adresse:'',
                PLZWohnort:'',
                jahrgang:'',
                partner_nr:'',
            }
        })
    } 
    const resetObjectModal = () => {
        dispatch({
            objectInfo:{
                ...contextState.objectInfo,
                Objektart:'Einfamilienhaus', 
                Nutzung:'selbstgenutzt', 
                Verkehrswert:'', 
                Adresse:'', 
                PLZ:'', 
                Ort:'', 
                Baujahr:'', 
                baurecht:'Nien', 
                Baurechtszins:'',
                Luxusobjekt:false, 
                Zentrumslage:0, 
                DauerBaurecht:''
            }
        })
    }
    
    const submitDetails = (e) => {
        e.preventDefault()

            dispatch({isSaveClientDetails:true})
            contextState.isInsert?(
                Axios.post('/api/clients', contextState.clientDetails)
                    .then((res) => {
                        dispatch({clientData:res.data})
                        resetClientModal()
                        susAlert()
                        dispatch({modalClient:true})
                        dispatch({isSaveClientDetails:false})
                    })
                    .catch((err) => {
                        ErrAlert(err)
                        dispatch({isSaveClientDetails:false})
                    })
            ):(
                // console.log(contextState.clientDetails)
                // console.log(contextState.clientDetails)
                Axios.put(`/api/clients/${contextState.clientId}`, contextState.clientDetails)
                    .then((res) => {
                        dispatch({clientData:res.data})
                        dispatch({isSaveClientDetails:false})
                        updtAlert()
                    })
                    .catch((err) => {
                        ErrAlert(err)
                        dispatch({isSaveClientDetails:false})
                    })
            )
    }  
    const [modalObject, setModalObject] = useState(false);
    const SubmitObject = (e, clientId) => {
        e.preventDefault()
        console.log(contextState.saveClientErr)
        if(!contextState.saveClientErr){
            dispatch({isSaveClientDetails:true})
            let addData = (!contextState.isInsertObject) ? {id : contextState.objectId} : {}
            Axios.post('/api/objectdata', {...contextState.objectInfo, client_id:clientId, oldProgStat:contextState.objCurTab, ...addData})
                .then((res) => { 
                    
                    dispatch({ObjektangabenList:res.data})
                    computeTotal(res.data)
                    dispatch({noObjectData:res.data.length <= 0 ? true:false})
                    setModalObject(false)
                    dispatch({newObjAdded: !contextState.isInsertObject?false:true})
                    dispatch({isSaveClientDetails:false})
                    if(contextState.isInsertObject){ 
                        resetObjectModal()
                        susAlert()
                    }else{
                        updtAlert()
                    } 
                })
                .catch((err) => {
                    ErrAlert(err)
                    dispatch({isSaveClientDetails:false})
                })
        }else{
            ErrAlert('Baujahr is Invalid')
        }
    }

    const computeTotal = (objectVerkeVal) => {
        let sumItem = 0
        objectVerkeVal.forEach(element => {
            sumItem += parseInt(element.Verkehrswert)
        });  
        dispatch({objectSumTotal:sumItem})
    }

    const checkClientCalc = (e) => {
        e.preventDefault()
        if(contextState.KundeGui.id == undefined){
            inputAlert()
        }else{
            saveClientCalc(contextState.KundeGui.calcName)
        }

    }
    const saveClientCalc = (e) => {
        Axios.post('/api/kundegui', {...contextState.KundeGui, Object_id:contextState.objectId, client_id:contextState.client_id, calcName: e , BsnespartNr:contextState.clientDetails.PartnerNr, Baurechtszins:contextState.objectInfo.Baurechtszins, })
            .then((res) => {
                dispatch({KundeGui:res.data[0]})
                dispatch({objCalcDataList:res.data})
                susAlert()
            })
            .catch((err) => {
                ErrAlert(err)
            })
    }



    
    const [swalAlert, setSwalAlert] = useState(null)
    const cnlAlert = () => {setSwalAlert(
        <SweetAlert error title={conTrans.cancel[conLoc]} btnSize="sm" onConfirm={() => setSwalAlert(null)}>
        </SweetAlert>
    )}
    const ErrAlert = (errMsg) => {setSwalAlert(
        <SweetAlert warning title={errMsg} btnSize="sm" onConfirm={() => setSwalAlert(null)}>
        </SweetAlert>
    )}
    const delAlert = () => {setSwalAlert(
            <SweetAlert success title="Deleted!" btnSize="sm" onConfirm={() => setSwalAlert(null)}>
            </SweetAlert>
    )}
    const susDelAlert = () => {setSwalAlert(
            <SweetAlert success title={conTrans.suc_del[conLoc]} btnSize="sm" onConfirm={() => setSwalAlert(null)}>
            </SweetAlert>
    )}
    const susAlert = () => {setSwalAlert(
            <SweetAlert success title={conTrans.suc_sav[conLoc]} btnSize="sm" onConfirm={() => setSwalAlert(null)}>
            </SweetAlert>
    )}
    const updtAlert = () => {setSwalAlert(
        <SweetAlert success title={conTrans.suc_upd[conLoc]} btnSize="sm" onConfirm={() => setSwalAlert(null)}>
        </SweetAlert>
    )}
    const inputAlert = () => {setSwalAlert(
        
        <SweetAlert required input showCancel cancelBtnText={conTrans.cancel[conLoc]} cancelBtnBsStyle="default" title=" " btnSize="sm" placeHolder={conTrans.wri_som[conLoc]} onConfirm={(e) => {saveClientCalc(e)}} onCancel={() => setSwalAlert(null) } >
            {conTrans.calcName[conLoc]}
        </SweetAlert>
    )}

    const hideAlert = () => {
        cnlAlert()
    } 
    const confirmAlert = (id, apiKey) => {
         Axios.delete(`/api/clients/${id}`)
            .then((res) => {
                susDelAlert()
                dispatch({clientData:res.data})
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const [alertType] = useState('warning')
    const delData = (id, apiKey) => {
        const getAlert = () => (
            <SweetAlert
                type={alertType}
                btnSize="sm"
                showCancel 
                confirmBtnText={conTrans.ydelit[conLoc]}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={conTrans.aysure[conLoc]}
                onConfirm={() => confirmAlert(id, apiKey)}
                onCancel={() => hideAlert()}
                cancelBtnText={conTrans.cancel[conLoc]}
            >
            </SweetAlert>
        )
        setSwalAlert(getAlert)
        
    }
    const confDelObject = (id, clientId, oldProgStat) => {
        console.log(oldProgStat)
        Axios.delete(`/api/objectdata/${id}/${clientId}/${oldProgStat}`)
            .then((res) => {
                dispatch({ObjektangabenList:res.data})
                computeTotal(res.data)
                dispatch({noObjectData:res.data.length <= 0 ? true:false})
                susDelAlert()
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const delObjectData = (id, clientId, oldProgStat) => {
        const getAlert = () => (
            <SweetAlert
                type={alertType}
                btnSize="sm"
                showCancel
                confirmBtnText={conTrans.ydelit[conLoc]}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={conTrans.aysure[conLoc]}
                onConfirm={() => confDelObject(id, clientId, oldProgStat)}
                onCancel={() => hideAlert()}
                cancelBtnText={conTrans.cancel[conLoc]}
            >
            </SweetAlert>
        )
        setSwalAlert(getAlert)

    }
    const openModalClient = () => {
        dispatch({modalClient:true})
        dispatch({isCalculate:false}) 
        dispatch({clientDetails:clientState})

    }
    const evn = (e, fieldName) => {
       
        dispatch({KundeGui:{...contextState.KundeGui, [fieldName]:numeral(e.currentTarget.value).value(),
             originalVal: fieldName == 'ISTEigenmittel' || fieldName == 'Kaufpreis'?false:true }})
    }
    const evnCli = (e, fieldName) => {
        dispatch({clientDetails:{...contextState.clientDetails, [fieldName]:numeral(e.currentTarget.value).value()}})
    }
    const inputPercent = (e, fieldName) => {
        if(e.currentTarget.value <= 100){
            if(!isNaN(e.currentTarget.value)){
                dispatch({KundeGui:{...contextState.KundeGui, [fieldName]:e.currentTarget.value,
                originalVal: true }})
            }
        }
    }
    // const checkIfSave = (id) => {
        // if((contextState.KundeGui.percentualeBelcal).toFixed(2) == contextState.KundeGui.percentualeBel){
        //     dispatch({isSave:true})
        // }else{
        //     console.log("false")
        //     // dispatch({isSave:false})
        // }
        // if(contextState.KundeGui.VerEinkom == contextState.KundeGui.VerEinkomcal){
        //     dispatch({isSave:true})
        // }else{
        //     console.log("false")
        //     // dispatch({isSave:false})
        // }
    //     Axios.get(`/api/kundegui/${id}`)
    //     .then((res) => {
    //         if(res.data.length > 0){
    //             // dispatch({KundeGui:{...res.data[0], percentualeBel: res.data[0].percentualeBelcal, VerEinkomcal:res.data[0].VerEinkom}})
    //             console.log(JSON.stringify(res.data) === contextState.KundeGui)
    //         }else{
    //             dispatch({KundeGui:KundeGuiState})
    //         }
    //         // console.log(res.data[0])
    //     })
    //     .catch((err) => {
    //         ErrAlert()
    //     })


    // }
    return (
      
        <AppContext.Provider value={{
            ...contextState, 
            KundeGuiState, 
            dispatch, 
            dispatches:dispatch, 
            SubmitObject, 
            submitDetails, 
            computeTotal, 
            checkClientCalc, 
            swalAlert, 
            delData, 
            delObjectData, 
            openModalClient, 
            evn, 
            ErrAlert, 
            objectInfoState, 
            clientState, 
            evnCli, 
            inputPercent, 
            setModalObject, 
            modalObject, 
            translation: contextState.translation, 
            locale: contextState.locale, 
            stickyHeader:contextState.stickyHeader, 
            contWrapper:contextState.contWrapper,
            newObjAdded:contextState.newObjAdded,
        }}>
           { props.children }
        </AppContext.Provider>
     )
}