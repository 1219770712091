import React, {useContext,useEffect} from 'react'
import { Form, Input, Label, FormGroup, Col, Button } from 'reactstrap';
import {AppContext} from '../../contexts/AppContext'

function InsertClient() {

    const {dispatch, clientDetails, submitDetails}= useContext(AppContext) 

    // useEffect(() => {
    //     console.log(clientDetails.kundenname)
    // }, [clientDetails])
    const checkLetter = (e, kunName) => {
        if(!e.currentTarget.value.match(/^[0-9]+$/) || e.currentTarget.value === '' ){
            dispatch({clientDetails:{...clientDetails, [kunName]:e.currentTarget.value}})
            console.log(e.currentTarget.value)
        }else{
            return false
        }
    }
    const checkNumber = (e) => {
        if(e.currentTarget.value.match(/^[0-9]+$/) || e.currentTarget.value === ''){
            dispatch({clientDetails:{...clientDetails, partner_nr:e.currentTarget.value}})
        }else{
            return false
        }
    }
    return (
        <>
            <div>
                <Form onSubmit={(e) => {submitDetails(e)}}>
                    <FormGroup row>
                        <Label for="Name" sm={4}>Customer Name I</Label>
                        <Col sm={8}>
                            <Input required type="text" name="Name"
                                value={clientDetails.kundennameI}
                                onChange={(e) => {
                                    checkLetter(e, 'kundennameI')
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Name" sm={4}>Customer Name II</Label>
                        <Col sm={8}>
                            <Input required type="text" name="Name"
                                value={clientDetails.kundennameII}
                                onChange={(e) => {
                                    checkLetter(e, 'kundennameII')
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Age" sm={4}>Address</Label>
                        <Col sm={8}>
                            <Input required type="text" name="Age" 
                                value={clientDetails.adresse}
                                onChange={(e) => dispatch({clientDetails:{...clientDetails, adresse:e.currentTarget.value}})}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Gender" sm={4}>Postal Code/City</Label>
                        <Col sm={8}>
                            <Input required type="text" name="Name" 
                                value={clientDetails.PLZWohnort}
                                onChange={(e) => dispatch({clientDetails:{...clientDetails, PLZWohnort:e.currentTarget.value}})}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Gender" sm={4}>Year of birth</Label>
                        <Col sm={8}>
                            <Input required type="text" name="Name"
                                value={clientDetails.jahrgang} 
                                onChange={(e) => dispatch({clientDetails:{...clientDetails, jahrgang:e.currentTarget.value}})}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Gender" sm={4}>Partner No.</Label>
                        <Col sm={8}>
                            <Input required type="text" name="Name"
                                value={clientDetails.partner_nr}
                                onChange={(e) => 
                                    checkNumber(e)
                                }
                            />
                        </Col>
                    </FormGroup>
                    <Button type="submit">Submit</Button>
                </Form>
            </div>
        </>
    )
}

export default InsertClient
