import React, {useContext, useEffect, useState} from 'react'
import {Input, Label, FormGroup, Col, Button, InputGroupText, InputGroup, InputGroupAddon} from 'reactstrap';
import './../clientStyle.css'
import {AppContext} from '../../../contexts/AppContext'
import { withRouter } from 'react-router-dom'
import Axios from 'axios'
import numeral from 'numeral'
import ObjCalcList from './objCalcList/ObjCalcList'
import TableSpinner from '../../common/TableSpinner';
// import ObjectCalculation from './objCalcList/ObjectCalculation'

const ClientPrivateSelfUsed = withRouter((props, {match}) => {
    const {dispatch, KundeGui, evn, clientDetails, objectIdForCalc, ErrAlert, KundeGuiState, objectVerkehrswertForCalc, loadingObjectCalcData, isSave, objectId, checkClientCalc, objectInfo, inputfaktorenList, HSRegion, inputPercent, translation, locale}= useContext(AppContext)
    const Gui = KundeGui
    const newNebenkos = Gui.NebenkostenDivident / 10000
    const [nebPer, setNebPer] = useState(0)
    
    //Total percentage Calculation
    const filterALLHR = HSRegion.filter(region => region.HR == 1)
    const isEinflussGef = filterALLHR.filter(region => region.Ort == objectInfo.Ort).length
    
    const faktorenfound = inputfaktorenList.filter(inputfaktoren => inputfaktoren.Objektkategorie == objectInfo.Objektart)

    let objectCatPercent = parseInt(faktorenfound[0].Regelbel) 
    
    let objectRegion = objectCatPercent += isEinflussGef> 0? parseInt(faktorenfound[0].EinflussGef == 'na'? 0:faktorenfound[0].EinflussGef):0

    let objectLuxus = objectRegion += objectInfo.Luxusobjekt == 1? parseInt(faktorenfound[0].EinflussLuxus == 'na'? 0:faktorenfound[0].EinflussLuxus):0

    let objectZentrum = objectLuxus += objectInfo.Zentrumslage == 1? parseInt(faktorenfound[0].Zentrumslage=='na'?0:faktorenfound[0].Zentrumslage):0
    let totalPercentage = objectZentrum //end
    
    useEffect(() => {
        dispatch({loadingObjectCalcData:true})
        Axios.get(`/api/kundegui/${objectIdForCalc}`)
            .then((res) => {
                if(res.data.length > 0){
                    dispatch({objCalcDataList:res.data})
                    dispatch({KundeGui:res.data[0]})
                    dispatch({objectId:objectIdForCalc})
                    dispatch({loadingObjectCalcData:false})
                }else{
                    dispatch({objCalcDataList:[]})
                    dispatch({KundeGui:KundeGuiState})
                    dispatch({objectId:objectIdForCalc})
                    dispatch({loadingObjectCalcData:false})
                }
            })
            .catch((err) => {
                ErrAlert()
            })
            
    }, [])
   

    const [solp, setSolp] = useState(0)
    
    useEffect(()=>{
        // checkIfSave(props.id)

        

            const calculateObjSumTot =  objectInfo.Verkehrswert * totalPercentage / 100
            const calculateISTKreditRight = objectInfo.Verkehrswert / KundeGui.ISTKredit
            const calculateISTKredit = Gui.Kaufpreis - Gui.ISTEigenmittel 
    
            // Calculation for Hypothek
            const hyppothekpercent = (objectInfo.Luxusobjekt == 0 ? faktorenfound[0].hypothek1 / 100 : faktorenfound[0].hypothek2 / 100) 
            
            // This is not yet finish, this should be 2 column only in the database for hypohek and soll-amortization
            // 
            //                                  Heeeeeeeeeeyyyyy!!!
            // 
            // calculation for Soll-Amortization 
            const IStKrePer = calculateISTKredit / objectInfo.Verkehrswert * 100

            const hypothekCalc1 = objectInfo.Verkehrswert * hyppothekpercent
            const hypoPercentage = hypothekCalc1 - Gui.ISTKredit
            

            const totalHypothek = faktorenfound[0].hypothek1 >= IStKrePer ? 0 : hypoPercentage //end
    
            
            let cd = Math.min(clientDetails.JahrgangI,  clientDetails.JahrgangI)
            let currentYear = new Date().getFullYear()
            let checkYear = Math.abs(cd - currentYear) - 65
            

            const sollAmorPercent = faktorenfound[0].SollAmortisation1 > 11 ? faktorenfound[0].SollAmortisation1 : parseInt(faktorenfound[0].SollAmortisation1) / 100
    
            const totalSollAmor = () => {
                if(faktorenfound[0].SollAmortisation1 > 11){
                    if(Math.abs(checkYear) > 15){
                        return Math.abs(totalHypothek / sollAmorPercent)
                    }else if(Math.abs(checkYear) < 15){
                        return Math.abs(totalHypothek / Math.abs(checkYear))
                    }
                }else{
                   return  Math.abs(KundeGui.ISTKredit * sollAmorPercent)          
                }
            }
    
            let comPer = KundeGui.SollAmortisDividend / 100
            let totalSollAmorFinal = totalSollAmor()
    
            const sollAmorTotalPercent = totalSollAmorFinal / KundeGui.ISTKredit * 100 //end
            let getInputSolPercent = KundeGui.SollAmortisDividend / 100
            
    
            let finalSolComp = ()  =>{

                if(!KundeGui.originalVal){ 
                    setSolp(parseFloat((totalSollAmorFinal / KundeGui.ISTKredit * 100).toFixed(2)))
                    return (totalSollAmorFinal / KundeGui.ISTKredit * 100).toFixed(2)
                }else{
                    return KundeGui.SollAmortisDividend
                }   
            } 
            let finalSolPer = finalSolComp()
            
            let finalSolAmor =  KundeGui.ISTKredit / 100 * finalSolPer 

            // if (Gui.originalVal == true || Gui.originalVal == undefined)
            

          

            let k = Gui.KalkZinsbelPercent / 100
            const calculateKalkZinsbelKredit = calculateISTKredit * k
        
    
            // calculation fo Nebenkosten
            let checkNebYr = Math.abs(currentYear - objectInfo.Baujahr)
            let checkNebPer = () => {
                if (checkNebYr < 5){
                    return faktorenfound[0].Nebenkosten1
                }else if(checkNebYr > 5 && checkNebYr <= 10){
                    return faktorenfound[0].Nebenkosten2
                }
                else if(checkNebYr > 10 && checkNebYr <= 15){
                    return faktorenfound[0].Nebenkosten3
                }
                else if(checkNebYr > 15 && checkNebYr <= 20){
                    return faktorenfound[0].Nebenkosten4
                }
                else if(checkNebYr > 20){
                    return faktorenfound[0].Nebenkosten5
                }
            }
            let finalNebPer = !KundeGui.originalVal ? KundeGui.NebenkostenDivident != 0 ? KundeGui.NebenkostenDivident: checkNebPer() : KundeGui.NebenkostenDivident
            let NebPer = finalNebPer / 100
            const calculateNebenkosten = objectInfo.Verkehrswert * NebPer 
    
            if (nebPer !== checkNebPer())
                {setNebPer(checkNebPer())}
    
            
            const sumTotalBelastung = [
                Gui.KalkZinsbel,
                Gui.SollAmortis,
                Gui.Nebenkosten,
                objectInfo.baurecht == 'Nien'?0:objectInfo.Baurechtszins,
            ]
            const convertsumTotalBelastung = sumTotalBelastung.map(parseFloat)
            const calculateTotalBelastung = convertsumTotalBelastung.reduce((a, b) => a + b, 0)
            const calculateTotalpercentualeBel = Gui.TotalBelastung / clientDetails.VerEinkom * 100
            
            const ISTKreditPercentage = calculateISTKredit / objectInfo.Verkehrswert * 100
            console.log(sumTotalBelastung)
            if(!loadingObjectCalcData){
                dispatch({
                    KundeGui:{
                    ...KundeGui, 
    
                    MaxKredit:calculateObjSumTot,
                    ISTKredit:calculateISTKredit, 
                    ISTKreditPercentage:IStKrePer,
                    ISTKreditRi: isNaN(calculateISTKreditRight) || calculateISTKreditRight === Infinity || calculateISTKreditRight === -Infinity?0:calculateISTKreditRight,
                    Anteil2Hypothek:Math.abs(totalHypothek),
                    KalkZinsbel:calculateKalkZinsbelKredit,
                    SollAmortis:isNaN(finalSolAmor) || finalSolAmor === Infinity || finalSolAmor === -Infinity?0:finalSolAmor,
                    SollAmortisDividend: isNaN(finalSolPer) || finalSolPer == Infinity || finalSolPer == -Infinity?0:finalSolPer,
                    Nebenkosten:calculateNebenkosten,
                    NebenkostenDivident: isNaN(finalNebPer) || finalNebPer === Infinity || finalNebPer === -Infinity?0:finalNebPer,
                    TotalBelastung:calculateTotalBelastung,
                    percentualeBelcal:isNaN(calculateTotalpercentualeBel) || calculateTotalpercentualeBel === Infinity || calculateTotalpercentualeBel === -Infinity?0:calculateTotalpercentualeBel
                }
            })
        }
    }, [
        Gui.MaxKredit, 
        Gui.Kaufpreis,
        Gui.ISTEigenmittel,
        Gui.ISTKredit,
        Gui.KalkZinsbel,
        Gui.KalkZinsbelPercent,
        Gui.Anteil2Hypothek,
        Gui.SollAmortis,
        Gui.SollAmortisDividend,
        Gui.Nebenkosten, 
        Gui.NebenkostenDivident, 
        Gui.TotalBelastung,
        objectInfo.Baurechtszins,
        clientDetails.VerEinkom,
        Gui.percentualeBelcal,
        Gui.ISTKreditPercentage,
        solp
    ])
    const [kauf, setKauf] = useState(false)
    const [ISTEi, setISTEi] = useState(false)
    const [Baurech, setBaurech] = useState(false)
    const [Eink, setEink] = useState(false)

    return (
        <>
            {
                loadingObjectCalcData? 
                <div className="page-cform shadow" style={{height:'500px'}}>
                    <TableSpinner />
                </div>
            :
            <div className="page-cform shadow">
                <div className="text-right">
                    <Button className=" add-client add-btn tooltips expand"  data-title={translation.new_pro_cal[locale]} style={{marginBottom: '70px', marginRight: '8px'}} onClick={(e) => { dispatch({KundeGui:KundeGuiState}) }}>
                        <i className="fas fa-plus"></i>
                    </Button>
                </div>         
                <div className="text-right">
                    <Button className=" add-client add-btn" style={{width: '60px', height: '60px'}} onClick={(e) => { checkClientCalc(e)  }}>
                        <i className="fas fa-save"></i>
                    </Button>
                </div>
                <div className="text-right">
                    <ObjCalcList></ObjCalcList>
                </div>
                <div className="container">
                    <div className="mt-4 mb-4">
                        <h3>{clientDetails.KundennameI} {translation.and[locale]} {clientDetails.KundennameII}</h3>
                        
                    </div>
                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.bus_nbr[locale]}</Label>
                        <Col sm="6">
                            {clientDetails.PartnerNr}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.obj_typ[locale]}</Label>
                        <Col sm="6" className="asbajs">
                            {objectInfo.Objektart}
                        </Col>
                            {/* <Table hover striped bordered>
                                <thead>
                                    <tr>
                                        <th>Objektart</th>
                                        <th>Nutzung</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ObjektangabenList.map((objekt, index) => 
                                        <tr key={index}>
                                            <th>{objekt.Objektart}</th>
                                            <th>{objekt.Nutzung}</th>
                                        </tr>
                                    )}
                                    
                                </tbody>
                            </Table> */}
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.use[locale]}</Label>
                        <Col sm="6">
                            {objectInfo.Nutzung}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.mar_val[locale]}</Label>
                        <Col sm="3" className="ta-4 text-al">
                            <label className="fl">Fr.</label> 
                            <b className="res-val">{ 
                                    numeral(objectInfo.Verkehrswert).format("0,0.00").split(',').join("'")
                            }
                            </b>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label sm={5}>{translation.max_cre[locale]}</Label>
                        <Col sm="3" className="ta-4 text-al">
                            <label className="fl">Fr.</label>
                            <b  style={{marginRight:'23%'}}>{ 
                                `${
                                    numeral(KundeGui.MaxKredit).format("0,0").split(',').join("'")
                                }`
                            }
                            </b>
                            <b >{ 
                                `(${parseInt(totalPercentage)} %)`
                                }
                            </b>
                        </Col>
                    </FormGroup>
                    
                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.pur_pri[locale]}</Label>
                        <Col sm="6">
                            <Input id="jj" type="text" name="Kundenname" 
                                value={ kauf?KundeGui.Kaufpreis: numeral(KundeGui.Kaufpreis).format("0,0").split(',').join("'")}
                                onFocus={() => {setKauf(true)}}
                                onBlur={() => {setKauf(false)}}
                                onChange={(e) => {
                                    evn(e, 'Kaufpreis')
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.own_funds[locale]}</Label>
                        <Col sm="6">
                            <Input type="text" name="Kundenname" 
                                value={ISTEi? KundeGui.ISTEigenmittel: numeral(KundeGui.ISTEigenmittel).format("0,0").split(',').join("'")}
                                onFocus={() => {setISTEi(true)}}
                                onBlur={() => {setISTEi(false)}}
                                onChange={(e) => {
                                    evn(e, 'ISTEigenmittel')
                                }}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.mortgage[locale]}</Label>
                        <Col sm="3" className="ta-4 text-al">
                            <label className="fl">Fr.</label>
                            <b style={{marginRight:'15%'}}>{ 
                                `${numeral(KundeGui.ISTKredit).format("0,0.00").split(',').join("'")}`
                                }
                            </b>
                            <b >{ 
                                `(${numeral(KundeGui.ISTKreditPercentage).format("0.00")}%)`
                                }
                            </b>
                        </Col>
                    </FormGroup>
                    
                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.sha_2nd_mor[locale]}</Label>
                        <Col sm="3" className="ta-4 text-al">
                            <label className="fl">Fr.</label>  
                            <b className="res-val">{ 
                                numeral(KundeGui.Anteil2Hypothek).format("0,0.00").split(',').join("'")
                            }
                            </b>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.lim_int_cha[locale]}</Label>
                        <Col sm="3" className="ta-4 text-al">
                            <label className="fl">Fr.</label>
                            <b className="res-val">{ 
                                numeral(KundeGui.KalkZinsbel).format("0,0.00").split(',').join("'")
                            }
                            </b>
                        </Col>
                            <Col sm="3">
                                <InputGroup>
                                <Input type="text" name="Kundenname"
                                    value={KundeGui.KalkZinsbelPercent}
                                    onChange={(e) => {
                                        inputPercent(e, 'KalkZinsbelPercent')
                                    }}
                                />
                                    <InputGroupAddon addonType="append" className="append-percent">
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>

                        <Label for="Kundenname" sm={5}>{translation.tar_pay[locale]}</Label>
                        <Col sm="3" className="ta-4 text-al">
                            <label className="fl">Fr.</label>
                            <b className="res-val">{ 
                                numeral(KundeGui.SollAmortis).format("0,0.00").split(',').join("'")
                            }
                            </b>
                        </Col>
                            <Col sm="3">
                                <InputGroup>
                                    <Input id="Soll-Amortisation" type="text" name="Kundenname"
                                        value={KundeGui.SollAmortisDividend}
                                        onChange={(e) => {
                                            inputPercent(e, 'SollAmortisDividend')
                                        }}
                                    />
                                    
                                    <InputGroupAddon addonType="append" className="append-percent">
                                        <InputGroupText 
                                            style={{display:solp == KundeGui.SollAmortisDividend || KundeGui.SollAmortisDividend == 0? 'initial' : 'none'}}>%</InputGroupText>
                                        <Button 
                                            onClick={() => { dispatch({KundeGui:{...KundeGui, SollAmortisDividend: solp}}) }} 
                                            style={{display:solp == KundeGui.SollAmortisDividend || KundeGui.SollAmortisDividend == 0? 'none' : 'initial'}}> 
                                                <i className="fas fa-undo"></i></Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>

                        <Label for="Kundenname" sm={5}>{translation.add_cos[locale]}</Label>
                        <Col sm="3" className="ta-4 text-al">
                            <label className="fl">Fr.</label> 
                            <b className="res-val">{ 
                                numeral(KundeGui.Nebenkosten).format("0,0.00").split(',').join("'")
                            }
                            </b>
                        </Col>
                            <Col sm="3">
                                <InputGroup>
                                    <Input type="text" name="Kundenname"
                                        value={KundeGui.NebenkostenDivident}
                                        placeholder="Percent(%)"
                                        onChange={(e) => {
                                            inputPercent(e, 'NebenkostenDivident')
                                        }}
                                    />
                                    <InputGroupAddon addonType="append" className="append-percent">
                                        <InputGroupText 
                                            style={{display:KundeGui.NebenkostenDivident == nebPer || KundeGui.NebenkostenDivident == 0? 'initial' : 'none'}}>%</InputGroupText>
                                            <Button 
                                                onClick={() => { dispatch({KundeGui:{...KundeGui, NebenkostenDivident: nebPer}}) }} 
                                                style={{display:KundeGui.NebenkostenDivident == nebPer || KundeGui.NebenkostenDivident == 0? 'none' : 'initial'}}> 
                                                    <i className="fas fa-undo"></i></Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>

                        <Label for="Kundenname" sm={5}>{translation.gro_ren[locale]}</Label>
                        <Col sm="6">
                            <Input type="text" name="Kundenname" 
                                disabled={objectInfo.baurecht == 'Nien'?'disabled':''}
                                value={objectInfo.baurecht == 'Nien'?'':Baurech?objectInfo.Baurechtszins: numeral(objectInfo.Baurechtszins).format("0,0").split(',').join("'")}
                                onFocus={() => {setBaurech(true)}}
                                onBlur={() => {setBaurech(false)}}
                                onChange={(e) => {
                                    dispatch({objectInfo:{...objectInfo, Baurechtszins:e.currentTarget.value}})
                                    
                                }}
                            />
                        </Col>

                        <Label for="Kundenname" sm={5}>{translation.tot_lia[locale]}</Label>
                        <Col sm="3" className="ta-4 text-al">
                            <label className="fl">Fr.</label>
                            <b className="res-val">{ 
                                numeral(KundeGui.TotalBelastung).format("0,0.00").split(',').join("'")
                            }
                            </b>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="Kundenname" sm={5}>{translation.income[locale]}</Label>
                        <Col sm="6">
                            <Input type="text" name="Kundenname" 
                                value={Eink?clientDetails.VerEinkom: numeral(clientDetails.VerEinkom).format("0,0").split(',').join("'")}
                                onFocus={() => {setEink(true)}}
                                onBlur={() => {setEink(false)}}
                                onChange={(e) => {
                                    dispatch({clientDetails:{...clientDetails, VerEinkom:numeral(e.currentTarget.value).value()}})
                                }}
                            />
                        </Col>

                        <Label for="Kundenname" sm={5}>{translation.lia_in_per[locale]} </Label>
                        <Col sm="3" className="ta-4 text-al" style={{paddingTop: '8px'}}>
                            {/* Fr. { parseFloat(KundeGui.percentualeBel).toFixed(2)}  % */}
                            <b className="res-val">{ 
                                // Number.isInteger(KundeGui.Nebenkosten)? KundeGui.Nebenkosten:(KundeGui.Nebenkosten).toFixed(2)
                                parseFloat(KundeGui.percentualeBelcal).toFixed(2)
                                }%
                            </b>
                        </Col>
                    </FormGroup>
                </div>
            </div>
        }
        </>
    )
})

export default ClientPrivateSelfUsed
