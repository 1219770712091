import React, { useContext, useEffect, useState } from 'react';
import {
  FormGroup,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { AppContext } from '../../contexts/AppContext';
import { withRouter } from 'react-router-dom';
import InsertClient from './InsertClient';
import './clientStyle.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import CustomerInfo from './CustomerInfo';
import emptyIcon from './../../brand/empty.svg';
import TableSpinner from '../common/TableSpinner';

const ClientList = withRouter(({ history }) => {
  const {
    dispatch,
    clientData,
    clientDetails,
    isInsert,
    KundeGui,
    swalAlert,
    delData,
    openModalClient,
    clientState,
    translation,
    locale,
    contWrapper,
    loadingClientData,
    isSaveClientDetails,
  } = useContext(AppContext);
  const { SearchBar } = Search;
  useEffect(() => {}, [clientData, clientDetails, isInsert]);

  const [modalClient, setModalClient] = useState(false);
  const loadModalUpdt = (cDetails) => {
    const detail = clientData
      .filter((detail) => detail.id == cDetails.id)
      .map((datum) => datum);
    dispatch({
      clientDetails: {
        ...detail[0],
      },
    });
    dispatch({ clientId: cDetails.id });
    setModalClient(true);
    dispatch({ isInsert: false });
  };

  useEffect(() => {
    if (!modalClient) {
      dispatch({
        clientDetails: { ...clientState },
      });
      dispatch({ isInsert: true });
    }
  }, [modalClient]);

  const columns = [
    { dataField: 'KundennameI', text: translation.CusNameI[locale] },
    { dataField: 'KundennameII', text: translation.CusNameII[locale] },
    { dataField: 'Adresse', text: translation.address[locale] },
    { dataField: 'PLZWohnort', text: translation.posCode[locale] },
    { dataField: 'PartnerNr', text: translation.partnerNo[locale] },
    { dataField: 'actions', text: translation.action[locale] },
  ];

  const gotoObject = (cDetails) => {
    dispatch({ loadingObjectData: true });
    history.push(`/calculate/${cDetails.id}/All`);
  };
  return (
    <>
      {swalAlert}
      <div
        className={
          contWrapper ? 'page-cform shadow cont-wrapper' : 'page-cform shadow'
        }
      >
        <div>
          <FormGroup row>
            <Col sm="12" className="text-right">
              <Button
                // alt={translation.addClient[locale]}
                className="add-client add-btn big-btn"
                onClick={() => {
                  openModalClient();
                }}
              >
                <i className="fas fa-plus"></i>
              </Button>
            </Col>
          </FormGroup>
        </div>
        <div className="container">
          <ToolkitProvider
            keyField="id"
            data={clientData.map((cDetails) => ({
              ...cDetails,
              actions: (
                <>
                  <Button
                    className="no-bg-warning"
                    size="sm"
                    onClick={() => {
                      dispatch({
                        KundeGui: {
                          ...KundeGui,
                          VerEinkom: 0,
                          percentualeBel: 0,
                        },
                        objectSumTotal: 0,
                      });
                      gotoObject(cDetails);
                    }}
                  >
                    <i className="fas fa-building obj-action-icon"></i>
                  </Button>{' '}
                  <Button
                    className="no-bg-info"
                    size="sm"
                    color="info"
                    onClick={() => {
                      loadModalUpdt(cDetails);
                    }}
                  >
                    <i className="fas fa-edit obj-action-icon"></i>
                  </Button>{' '}
                  <Button
                    className="no-bg-danger"
                    size="sm"
                    color="danger"
                    onClick={() => {
                      delData(cDetails.id, 'objectData');
                    }}
                  >
                    <i className="fas fa-trash obj-action-icon"></i>
                  </Button>
                </>
              ),
            }))}
            columns={columns}
            search
          >
            {(clientData) => (
              <div>
                <SearchBar
                  placeholder={translation.search[locale]}
                  {...clientData.searchProps}
                />
                <hr />
                <BootstrapTable
                  // striped
                  keyField="id"
                  bordered={false}
                  hover
                  {...clientData.baseProps}
                  columns={columns}
                  pagination={paginationFactory()}
                  noDataIndication={() =>
                    loadingClientData ? (
                      <TableSpinner />
                    ) : (
                      <div className="text-center" style={{ width: '100%' }}>
                        <img src={emptyIcon} />
                        <br />
                        <small>{translation.noDataAvail[locale]}</small>
                      </div>
                    )
                  }
                />
              </div>
            )}
          </ToolkitProvider>
        </div>

        <Modal
          size="lg"
          isOpen={modalClient}
          toggle={() => setModalClient(!modalClient)}
        >
          <ModalHeader toggle={() => setModalClient(!modalClient)}>
            <div>
              <h3>{translation.updCliIn[locale]}</h3>
            </div>
          </ModalHeader>
          <ModalBody>
            {/* <InsertClient /> */}
            <CustomerInfo />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
});

export default ClientList;
