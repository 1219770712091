import React, {useContext} from 'react'
import { AppContext } from "../../contexts/AppContext";
import numeral from 'numeral'

const HeadBanner = ({title, description, icon, callback, withButton}) => {
   const {dispatch, objectSumTotal, KundeGui, clientDetails, translation, locale, clientData} = useContext(AppContext)
   
  return (
   <div className="d-flex justify-content-between align-items-center mb-2">
      <div className="d-flex justify-content-center align-items-center circle">
         <p className="mb-0 item-nr"><i className={icon}></i></p>
      </div>

      <div className="flex-grow-1">
         {/* <div className="lb-3 d-flex justify-content-between align-items-center custom-header">
            <h5 className="ml-3 mb-0"><strong>{ title }</strong></h5>
            {
               withButton?
               <span className="icon mr-3 mb-1" onClick={callback}><i className="fas fa-plus-circle fa-2x"></i></span>: ''
            }
         </div> */}
         <div className="row">
            <div className="col-md-10"><h5 className="ml-3 mb-0"><strong>{ title }</strong></h5></div>
            {/* {
               title == 'Object' || title == 'Objekt' || title == 'Calculation' || title == 'Berechnung'? 
                  <div className="col-md-8 header-details" >
                     <h5 className="ml-3 mb-0 header-total"> */}

                        {/* { this is the total in the header before
                           title == 'Calculation' || title == 'Berechnung'?
                              <strong className="header-tot-val">
                                 {translation.lia_in_per[locale]}:
                                 <span>
                                    {
                                       parseFloat(KundeGui.percentualeBelcal).toFixed(2)+" %"
                                    }
                                 </span> 
                              </strong>
                           :''
                        }
                        <strong className="header-tot-val">
                           {translation.objTot[locale]}:
                           <span>
                              {" "}CHF{" "}{numeral(objectSumTotal).format("0,0.00").split(",").join("'")}
                           </span>
                        </strong>
                     
                        <strong className="header-tot-val">
                           {translation.dis_in[locale]}:
                           <span>
                              {" "}CHF{" "}{numeral(clientDetails.VerEinkom).format("0,0.00").split(",").join("'")}
                           </span>
                        </strong> */}

                        
                     {/* </h5>
                  </div>
               :title == 'Client' || title == 'Kunden'? 
                  <div className="col-md-8 header-details" style={{textAlign:'end'}}>
                     <h5 className="ml-3 mb-0 header-total">
                        <strong className="header-tot-val">
                           Total {translation.client[locale]}:
                           <span>
                              {" "}{clientData.length}
                           </span>
                        </strong>
                     </h5>
                  </div>
                  
               :''
            } */}
         </div>
         <div>
            <small className="ml-3 mb-0 mt-2">{ description }</small>
         </div>
      </div>

   </div>
  )
}

export default HeadBanner
