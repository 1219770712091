import React, { useEffect, useContext, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { Doughnut, Bar, HorizontalBar, Pie } from 'react-chartjs-2';
import numeral from 'numeral';
import { Input } from 'reactstrap';
import { DoughnutChart, BarChart, HorizontalBarChart, PieChart } from '../../../data/chartsColorScheme.json';

const SummaryDetails = () => {
  const {
    dispatch,
    clientData,
    ObjektangabenList,
    dashboard,
    translation,
    locale,
    contWrapper,
  } = useContext(AppContext);

  var db = dashboard;
  const [showChartOf, setSHpwChartOf] = useState(3);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [clientDashState, setClientDashState] = useState(true);
  const [objDashState, setObjDashState] = useState(true);
  const [objInprogDashState, setObjInprogDashState] = useState(true);

  const objStatus = {
    labels: [
      translation.sin_fam_liv_hous[locale],
      translation.mul_fam_dwe[locale],
      translation.con_apar[locale],
      translation.off_buil[locale],
      translation.com_buil[locale],
      translation.ind_buil[locale],
    ],
    datasets: [
      {
        data: [
          objDashState ? 58 : 72500000,
          objDashState ? 48 : 42000000,
          objDashState ? 50 : 65000000,
          objDashState ? 34 : 64500000,
          objDashState ? 46 : 71000000,
          objDashState ? 40 : 86000000,
          // db.objActVerk,
          // db.objInActVerk
        ],
        backgroundColor:DoughnutChart.bgColor,
        hoverBackgroundColor:DoughnutChart.hoverBgColor,
        width:300,
        borderColor:DoughnutChart.bColor,
        borderWidth:1
      },
    ],
  };

  // const objProgress = {
  // labels:  ['In Progress', 'Accpeted', 'Offered', 'Dismissed'],
  // datasets: [
  //     {
  //         data: [db.objOpen, db.objAccepted, db.objOffer, db.objDismissed],
  //         backgroundColor:['#f7bb71de', '#7dc578e0', '#85d0e6de', 'pink'],
  //         hoverBackgroundColor:'#b5b5b5de',
  //         width:300
  //     }
  // ],
  // }
  const objProgressBar = {
    labels: [
      'Jan',
      'Feb',
      translation.mar[locale],
      'Apr',
      translation.may[locale],
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      translation.oct[locale],
      'Nov',
      translation.dec[locale],
    ],
    datasets: [
      {
        data: [
          objInprogDashState ? 32 : 24339276,
          objInprogDashState ? 34 : 25224362,
          objInprogDashState ? 33 : 25362369,
          objInprogDashState ? 38 : 28674534,
          objInprogDashState ? 42 : 36024706,
          objInprogDashState ? 40 : 30449720,
          objInprogDashState ? 42 : 32024756,
          objInprogDashState ? 45 : 34612345,
          objInprogDashState ? 0 : 0,
          objInprogDashState ? 0 : 0,
          objInprogDashState ? 0 : 0,
          objInprogDashState ? 0 : 0,
          // db.inProgJan,
          // db.inProgFeb,
          // db.inProgMar,
          // db.inProgApr,
          // db.inProgMay,
          // db.inProgJun,
          // db.inProgJul,
          // db.inProgAug,
          // db.inProgSep,
          // db.inProgOct,
          // db.inProgNov,
          // db.inProgDec,
          0,
        ],
        backgroundColor:BarChart.bgColor,
        //hoverBackgroundColor:'#b5b5b5de', 
        hoverBackgroundColor:BarChart.hoverBgColor, 
        borderColor:BarChart.bColor,
        borderWidth:1,
      },
    ],
  };
  const objProgressHori = {
    labels: [
      translation.in_prog[locale],
      translation.offered[locale],
      translation.accepted[locale],
      translation.dismissed[locale],
    ],
    datasets: [
      {
        data: [
          objInprogDashState ? 189 : 144111346,
          objInprogDashState ? 306 : 233322858,
          objInprogDashState ? 285 : 217310505,
          objInprogDashState ? 21 : 16012353,
          // db.objOpenVerk,
          // db.objOfferVerk,
          // db.objAcceptedVerk,
          // db.objDismissedVerk,
          0,
        ],
        backgroundColor:HorizontalBarChart.bgColor,
        borderColor : HorizontalBarChart.bColor,
        borderWidth : 1,
        // hoverBackgroundColor:'#b5b5b5de',
        hoverBackgroundColor:HorizontalBarChart.hoverBgColor,
        ticks: {
            // Include a dollar sign in the ticks
            callback: function(value, index, values) {
                return '$' + value;
            }
        }
      },
    ],
  };

  useEffect(() => {
    if (showChartOf == 3) {
      if (ObjektangabenList.length != 0) {
        let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        let inProgSum1 = {
          inProgJan: 0,
          inProgFeb: 0,
          inProgMar: 0,
          inProgApr: 0,
          inProgMay: 0,
          inProgJun: 0,
          inProgJul: 0,
          inProgAug: 0,
          inProgSep: 0,
          inProgOct: 0,
          inProgNov: 0,
          inProgDec: 0,
        };

        months.forEach((month) => {
          let inProgSum = 0;
          ObjektangabenList.reduce((total, obj) => {
            if (
              obj.progress == 'In progress' &&
              parseInt(obj.createdAt.split('-')[1]) == month
            )
              return (inProgSum += parseInt(obj.Verkehrswert));
          });

          let dispatchMonth =
            month == 1
              ? 'inProgJan'
              : month == 2
              ? 'inProgFeb'
              : month == 3
              ? 'inProgMar'
              : month == 4
              ? 'inProgApr'
              : month == 5
              ? 'inProgMay'
              : month == 6
              ? 'inProgJun'
              : month == 7
              ? 'inProgJul'
              : month == 8
              ? 'inProgAug'
              : month == 9
              ? 'inProgSep'
              : month == 10
              ? 'inProgOct'
              : month == 11
              ? 'inProgNov'
              : month == 12
              ? 'inProgDec'
              : '';
          inProgSum1[dispatchMonth] = inProgSum;
        });

        dispatch({ dashboard: { ...dashboard, ...inProgSum1 } });
      }
    }
  }, [showChartOf]);

  useEffect(() => {}, [dashboard]);

  //   console.log((ObjektangabenList.createdAt).format("MM/DD/YYYY"))

  const [chartYear, setChartYear] = useState(2019);

  const clientGroupByAge = {
    labels: [
      translation.age30[locale],
      translation.age3050[locale],
      translation.age50[locale],
    ],
    datasets: [
      {
        data: [
          45,
          86,
          158,
          // db.objActVerk,
          // db.objInActVerk
        ],
        backgroundColor:PieChart.bgColor,
        hoverBackgroundColor:PieChart.hoverBgColor,
        width:300,
        borderColor:PieChart.bColor,
        borderWidth:1
      },
    ],
  };
  const clientGroupByIncome = {
    labels: [
      translation.inc100k[locale],
      translation.inc100k150k[locale],
      translation.inc150k[locale],
    ],
    datasets: [
      {
        data: [
          55,
          106,
          128,
          // db.objActVerk,
          // db.objInActVerk
        ],
        backgroundColor:['rgb(129, 176, 217)', 'rgb(98, 107, 213)', 'rgb(216, 107, 207)'],
        hoverBackgroundColor:'rgba(214, 214, 214, 0.98)',
        width:300,
        borderColor:['#257ac2e6', '#2330c8f0', '#c823bbe0'],
        borderWidth:1
      },
    ],
  };

  const commaSeparatorDoug = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var value = data.datasets[0].data[tooltipItem.index];
          value = value.toString();
          value = value.split(/(?=(?:...)*$)/);
          value = value.join("'");
          return value;
        },
      }, // end callbacks:
    }, //end tooltips
    maintainAspectRatio: false,
  };

  const commaSeparator = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var value = data.datasets[0].data[tooltipItem.index];
          value = value.toString();
          value = value.split(/(?=(?:...)*$)/);
          value = value.join("'");
          return value;
        },
      }, // end callbacks:
    }, //end tooltips
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            userCallback: function (value, index, values) {
              // Convert the number to a string and splite the string every 3 charaters from the end
              if (typeof value == 'number') {
                value = value.toString();
                value = value.split(/(?=(?:...)*$)/);
                value = value.join("'");
              }
              return value;
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            userCallback: function (value, index, values) {
              // Convert the number to a string and splite the string every 3 charaters from the end
              if (typeof value == 'number') {
                value = value.toString();
                value = value.split(/(?=(?:...)*$)/);
                value = value.join("'");
              }
              return value;
            },
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  return (
    <>
      <div
        className={
          contWrapper
            ? 'custom-cont-fluid db-box cont-wrapper'
            : 'custom-cont-fluid db-box'
        }
      >
        <div style={{ width: '100%', margin: 'auto' }}>
          <div className="row">
            <div className="col-md-4 text-left">
              <div
                className="toast fade show"
                role="alert"
                onClick={() => {
                  setSHpwChartOf(1);
                }}
              >
                <div className="dashboard-details-Hover"></div>
                <div className="row">
                  <div className="col-3">
                    <i className="fa fa-user dash-icon"></i>
                  </div>
                  <div className="col-9">
                    {showChartOf == 1 ? (
                      <span className="active-box"></span>
                    ) : (
                      ''
                    )}
                    <span className="dash-box-text label-details">
                      {translation.client[locale]}
                    </span>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="clientDashState"
                        defaultChecked
                        onClick={(e) => {
                          setClientDashState(!clientDashState);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="clientDashState"
                      >
                        {clientDashState
                          ? translation.by_age[locale]
                          : translation.by_inc[locale]}
                      </label>
                    </div>
                    <div className="toast-body mb-0 font-weight-bold text-gray-800 label-details c-font">
                      {clientDashState ? 289 : "CHF 37'256'849"}
                      {/* {`${clientData.length}`} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="toast fade show"
                role="alert"
                onClick={() => {
                  setSHpwChartOf(2);
                }}
              >
                <div className="dashboard-details-Hover"></div>
                <div className="row">
                  <div className="col-3">
                    <i className="fas fa-building dash-icon"></i>
                  </div>
                  <div className="col-9">
                    {showChartOf == 2 ? (
                      <span className="active-box"></span>
                    ) : (
                      ''
                    )}
                    <span className="dash-box-text label-details">
                      {translation.active[locale]}
                    </span>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="objDashState"
                        defaultChecked
                        onClick={(e) => {
                          setObjDashState(!objDashState);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="objDashState"
                      >
                        {objDashState
                          ? translation.num[locale]
                          : translation.mar_val[locale]}
                      </label>
                    </div>

                    <div className="toast-body mb-0 font-weight-bold text-gray-800 label-details c-font">
                      {objDashState ? 276 : "CHF 410'000'000"}
                      {/* Active: {dashboard.objActive}<br />
                                            Inactive: {dashboard.objInActive} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="toast fade show"
                role="alert"
                onClick={() => {
                  setSHpwChartOf(3);
                }}
              >
                <div className="dashboard-details-Hover"></div>
                <div className="row">
                  <div className="col-3">
                    <i className="fa fa-tasks dash-icon"></i>
                  </div>
                  <div className="col-9">
                    {showChartOf == 3 ? (
                      <span className="active-box"></span>
                    ) : (
                      ''
                    )}
                    <span className="dash-box-text label-details">
                      {translation.in_prog[locale]}
                    </span>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="objInprogDashState"
                        defaultChecked
                        onClick={(e) => {
                          setObjInprogDashState(!objInprogDashState);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="objInprogDashState"
                      >
                        {objInprogDashState
                          ? translation.num[locale]
                          : translation.cre_vol[locale]}
                      </label>
                    </div>

                    <div className="toast-body mb-0 font-weight-bold text-gray-800 label-details c-font">
                      {objInprogDashState ? 189 : "CHF 144'111'346"}

                      {/* In progress : {dashboard.objOpen}<br />
                                            Accepted: {dashboard.objAccepted}<br />
                                            Offer : {dashboard.objOffer}<br />
                                            Dismissed: {dashboard.objDismissed} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '60px' }}>
        {showChartOf == 1 ? (
          <>
            {clientDashState ? (
              <div className="text-center">
                <h3>{translation.client_chart1[locale]}</h3>
                <br />
                <div style={{ width: '100%', height: '350px', margin: 'auto' }}>
                  <Pie
                    data={clientGroupByAge}
                    width={20}
                    height={20}
                    options={{ maintainAspectRatio: false }}
                  />
                </div>
              </div>
            ) : (
              <div className="text-center">
                <h3>{translation.client_chart2[locale]}</h3>
                <br />
                <div style={{ width: '100%', height: '350px', margin: 'auto' }}>
                  <Pie
                    data={clientGroupByIncome}
                    width={20}
                    height={20}
                    options={{ maintainAspectRatio: false }}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          ''
        )}

        {showChartOf == 2 ? (
          <div>
            <h3 style={{ textAlign: 'center' }}>
              {translation.active_chart[locale]}
            </h3>
            <br />
            <div style={{ width: '600px', height: '400px', margin: 'auto' }}>
              <Doughnut
                data={objStatus}
                width={20}
                height={20}
                options={commaSeparatorDoug}
              />
            </div>
          </div>
        ) : (
          ''
        )}

        {showChartOf == 3 ? (
          <>
            <div className="row">
              <div className="cus col-md-12 mb-5">
                <Input
                  required
                  type="select"
                  className="obj-select"
                  value={chartYear}
                  onChange={(e) => {
                    setChartYear(e.currentTarget.value);
                  }}
                >
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                </Input>
              </div>
            </div>
            <div className="row">
              <div className="col-6 text-center">
                <h3>
                  {objInprogDashState
                    ? translation.prog_chart1[locale]
                    : translation.prog_chart1_1[locale]}
                </h3>
                <br />
                <div style={{ width: '100%', height: '300px', margin: 'auto' }}>
                  <Bar
                    data={objProgressBar}
                    options={commaSeparator}
                    legend={false}
                    width={600}
                    height={375}
                  />
                </div>
              </div>
              <div className="col-6 text-center">
                <h3>
                  {objInprogDashState
                    ? translation.prog_chart2[locale]
                    : translation.prog_chart2_1[locale]}
                </h3>
                <br />
                <div style={{ width: '100%', height: '300px', margin: 'auto' }}>
                  <HorizontalBar
                    data={objProgressHori}
                    options={commaSeparator}
                    legend={false}
                    width={600}
                    height={375}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default SummaryDetails;
