import React, {useContext, useEffect, useState} from 'react'
import {Button, Modal, ModalHeader, ModalBody, Table, Input, Dropdown, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,} from 'reactstrap'

// import Modal from 'react-modal'
import {AppContext} from '../../../contexts/AppContext'
import { withRouter } from 'react-router-dom'
import Axios from 'axios'
import ObjectInfo from './ObjectInfo';
import numeral from 'numeral'
import emptyIcon from './../../../brand/empty.svg' 
import TableSpinner from '../../common/TableSpinner'

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const ClientObjectTable = withRouter(({history,match}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    
    const toggle = () => setDropdownOpen(prevState => !prevState);
    
    const {dispatch, ObjektangabenList, objectInfo, isInsertObject, loadingObjectData, noObjectData, delObjectData, swalAlert, objectInfoState, isCalculate, KundeGuiState, setModalObject, modalObject, translation, locale, objCurTab}= useContext(AppContext)
    
    const [updtProgress, setUpdtProgress] = useState(false)
    const [updtStatus, setUpdtStatus] = useState(false)

    useEffect(() => {
        const fetchObjectData = async() => {
          
            await Axios.get('/api/inputfaktoren')
                .then((res) => {
                    dispatch({inputfaktorenList: res.data})
                })
                .catch((err) => {
                    console.log(err)
                })
                
        }
        fetchObjectData()
    }, [modalObject, isInsertObject])

    const objectModalUpdt = (objekt, oldProgStat) => {

        dispatch({
            objectInfo:{
                ...objectInfo, 
                Objektart:objekt.Objektart, 
                Nutzung:objekt.Nutzung, 
                Verkehrswert:objekt.Verkehrswert, 
                Adresse:objekt.Adresse, 
                PLZ:objekt.PLZ, 
                Ort:objekt.Ort, 
                Baujahr:objekt.Baujahr, 
                baurecht:objekt.baurecht, 
                Baurechtszins:objekt.Baurechtszins, 
                Luxusobjekt:objekt.Luxusobjekt,
                Zentrumslage:objekt.Zentrumslage, 
                DauerBaurecht:objekt.DauerBaurecht,
                progress:oldProgStat
            }
        })
        dispatch({objectId:objekt.id})
        setModalObject(true)
        dispatch({isInsertObject:false})
        // dispatch({objectInfo:{...objectInfo, progress:oldProgStat}})

    }
    const gotoObjectCalc = (objekt) => {
        // dispatch({KundeGui:{...KundeGuiState}})

        dispatch({KundeGui:KundeGuiState})
        dispatch({objectIdForCalc:objekt.id})
        dispatch({objectInfo:{...objekt}})
        dispatch({isCalculate:!isCalculate})
    } 
    useEffect(() => {
        Axios.get('/api/hotspotRegion')
            .then((res) => {
                dispatch({HSRegion:res.data})
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    const chgeObjprogress = (e, id, oldProgStat) => {
        dispatch({ObjektangabenList:[]})
        dispatch({loadingObjectData:true})
        
        Axios.post('/api/objectdata', {progress:e.currentTarget.value, id:id, client_id:match.params.id, oldProgStat:oldProgStat})
            .then((res) => {
                dispatch({ObjektangabenList:res.data})
                dispatch({noObjectData:res.data.length <= 0 ? true:false})
                dispatch({loadingObjectData:false})
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const chgeObjStatus = (e, id, oldProgStat) => {
        Axios.post('/api/objectdata', {status:e.currentTarget.value, id:id, client_id:match.params.id, oldProgStat:oldProgStat})
        .then((res) => {
            dispatch({ObjektangabenList:res.data})
        })
        .catch((err) => {
            console.log(err)
        })
    }
    


    //customize progress header function
    function progressFunc(column, colIndex){
        return (
            (
                <div>
                    { column.text }
                    <span style={{display:noObjectData?'none':'initial'}} onClick={()=>{
                        setUpdtProgress(!updtProgress)
                    }}>
                        {' '}{updtProgress? <i className="fas fa-arrow-left updt-icon"></i>:<i className="fas fa-pen updt-icon"></i>}
                    </span>
                </div>
            )
        );
    }
    //customize progress header function
    function statusFunc(column, colIndex){
        return (
            (
                <div>
                    { column.text }
                    <span style={{display:noObjectData?'none':'initial'}} onClick={()=>{
                        setUpdtStatus(!updtStatus)
                    }}>
                        {' '}{updtStatus? <i className="fas fa-arrow-left updt-icon"></i>:<i className="fas fa-pen updt-icon"></i>}
                    </span>
                </div>
            )
        );
    }

    const columns = [
        { dataField: "Objektart", text: translation.obj_typ[locale]},
        { dataField: "Nutzung", text: translation.usage[locale] },
        { dataField: "Baujahr", text: translation.yr_of_buil[locale] },
        { dataField: "Verkehrswert", text: translation.objTot[locale]},
        { 
            dataField: "status", 
            text: 'Status',
            headerFormatter: statusFunc
        },
        { 
            dataField: "progress", 
            text: translation.progress[locale],
            headerFormatter: progressFunc
        },
        { dataField: "actions", text: translation.action[locale] },
    ];
    const { SearchBar } = Search;

    return (
        <>
            
        
            <div className="container">
                <div className="text-right md-4">
                    <Button className="add-client add-btn big-btn tooltips expand"  data-title={translation.new_obj[locale]} onClick={() => { 
                        setModalObject(true); 
                        dispatch({objectInfo:objectInfoState})
                        dispatch({isInsertObject:true})
                    }}>
                        <i className="fas fa-plus"></i>
                    </Button>
                </div>
                {swalAlert}

                <div className="container">
                
                    <ToolkitProvider
                        keyField="id"
                        data={ObjektangabenList.map(objekt => ({
                        ...objekt,
                        Verkehrswert:(
                            <>
                                {numeral(objekt.Verkehrswert).format("0,0.00").split(',').join("'")}
                            </>
                        ),
                        status:(
                            <>
                                {
                                    !updtStatus?
                                    
                                        <span className={
                                            objekt.status == 'Active'? 'stat-style status-active':'stat-style status-inactive'
                                        }>
                                            {objekt.status == 'Active'?translation.active[locale]
                                            :translation.inactive[locale]}
                                        </span>
                                    :
                                        <Input required type="select"
                                            className="obj-select" 
                                            value={objekt.status}
                                            onChange={(e) =>  {
                                                chgeObjStatus(e, objekt.id, objCurTab)
                                            }}
                                        >
                                                <option value="Active">{translation.active[locale]}</option>
                                                <option value="Inactive">{translation.inactive[locale]}</option>
                                        </Input>
                                }
                            </>
                        ),
                        progress:(
                            <>
                                {
                                    !updtProgress?
                                        <span 
                                            style={{opacity:objekt.status == 'Inactive'?'0.5':'1'}}
                                            className={
                                                objekt.progress == 'In progress'? 'stat-style inprog':'stat-style '+objekt.progress 
                                        }>
                                            {
                                                objekt.progress == 'In progress'?translation.in_prog[locale]
                                                :objekt.progress == 'Offered'?translation.offered[locale]
                                                :objekt.progress == 'Accepted'?translation.accepted[locale]
                                                :objekt.progress == 'Dismissed'?translation.dismissed[locale]
                                                :''
                                            
                                            }
                                        </span>
                                    :
                                        <Input required type="select"
                                            style={{position:'relative'}}
                                            className="obj-select"
                                            disabled={objekt.status == 'Inactive'?'disabled':''}
                                            value={
                                                objekt.progress
                                            }
                                            onChange={(e) =>  {
                                                chgeObjprogress(e, objekt.id, objekt.progress)
                                            }}
                                            >
                                                <option value=""></option>
                                                <option value="In progress">{translation.in_prog[locale]}</option>
                                                <option value="Offered">{translation.offered[locale]}</option>
                                                <option value="Accepted">{translation.accepted[locale]}</option>
                                                <option value="Dismissed">{translation.dismissed[locale]}</option>
                                        </Input>
                                }
                            </>
                        ),
                        actions: (
                            <>
                                <UncontrolledDropdown id="lang" className="ml-2 obj-drop-btn"  inNavbar>
                                    <DropdownToggle disabled={objekt.status == 'Inactive'?'disbaled':''} 
                                                    style={{opacity:objekt.status == 'Inactive'?'0.3':'1'}}>
                                        <i className="fas fa-ellipsis-v obj-action-icon"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right className="mb-2" style={{ fontSize: '.795rem' }}>
                                        <DropdownItem 
                                            onClick={() => { 
                                                gotoObjectCalc(objekt);
                                                dispatch({client_id:match.params.id})
                                            }}>
                                            <Button className="no-bg-warning" size="sm" 
                                                disabled={objekt.status == 'Inactive'?'disabled':''}>
                                                <small>
                                                    <i className="fas fa-calculator obj-action-icon"></i>{" "}{translation.calculate[locale]}
                                                </small>  
                                            </Button> {' '}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => { objectModalUpdt(objekt, objekt.progress) }}>
                                            <Button className="no-bg-info" size="sm" 
                                                disabled={objekt.status == 'Inactive'?'disabled':''}>
                                                    <small>
                                                        <i className="fas fa-edit obj-action-icon" ></i>{""}{translation.update[locale]}
                                                    </small>
                                            </Button>{' '}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => { delObjectData(objekt.id, match.params.id, objekt.progress) }}>
                                            <Button className="no-bg-danger" size="sm" 
                                                disabled={objekt.status == 'Inactive'?'disabled':''}>
                                                <small>
                                                    <i className="fas fa-trash obj-action-icon" ></i>{" "}
                                                    {translation.delete[locale]}
                                                </small>
                                            </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </>
                        )
                        }))}
                        columns={columns}
                        search
                    >
                        {ObjektangabenList => (
                            <div>
                                <SearchBar placeholder={translation.search[locale]} {...ObjektangabenList.searchProps} /> <hr />
                                <BootstrapTable
                                    keyField="id"
                                    bordered={false}
                                    hover
                                    {...ObjektangabenList.baseProps}
                                    columns={columns}
                                    pagination={paginationFactory()}
                                    noDataIndication={() => (
                                        
                                        loadingObjectData?
                                            <TableSpinner />
                                        :
                                        <div className="text-center" style={{width:'100%'}}>
                                            <img src={emptyIcon} /><br/>
                                            <small>{translation.noDataAvail[locale]}</small>
                                        </div>
                                    )}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                    <Modal size="lg" isOpen={modalObject} toggle={() => setModalObject(!modalObject)}>
                        <ModalHeader toggle={() => setModalObject(!modalObject)}>
                            <div>
                                <h3>{translation.obj_dat[locale]}</h3>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <ObjectInfo />
                        </ModalBody>
                    </Modal> 
                </div>

            </div>
        </>
    )
})

export default ClientObjectTable
