import React, {useContext, useEffect, useState} from 'react'
import {AppContext} from '../../../../contexts/AppContext'
import {Input, Button} from 'reactstrap'
import Axios from 'axios';
import ObjectCalculation from './ObjectCalculation'
import './Style.css'
import emptyIcon from './../../../../brand/empty.svg' 


const ObjCalcList = () => {
    const {dispatch, objCalcDataList, KundeGui, translation, locale} = useContext(AppContext)
    const [calcList, setCalcList] = useState(false)


    const showList = (e) => {
        e.preventDefault()
        setCalcList(!calcList)
    }
    
    return (
        <>
            <div id="cart">
                <ul id="checkout">
                        <a href="#" data-tip="Add to Cart" className="product-cart" onClick={(e) => {showList(e)}}>
                            <i className="fa fa-calculator"></i>
                            <span className="cart-qty">{objCalcDataList.length}</span>
                        </a>
                        <ul id="itemList" className="shadow" style={{display:calcList?'block':'none'}}>
                            {/* <span className="text-center cart-total">Total: ₱</span><span className="text-center cart-total-num">234</span> */}
                                {objCalcDataList.map((objCalc) => (
                                    
                                        <li  key={objCalc.id} style={{backgroundColor:KundeGui.id == objCalc.id? '#80808061':''}} className="obj-row-list" onClick={() => { dispatch({KundeGui: objCalc}) }}>
                                            <div  className="row mb-1">
                                                <div className="col-1">
                                                    {KundeGui.id == objCalc.id? <i className="fa fa-chevron-right"></i>:''}
                                                </div> 
                                                <div className="col-7 text-left" >
                                                    <span><b>{objCalc.calcName}</b></span>
                                                </div> 
                                                <div className="col-3">
                                                    <span className="text-center">
                                                        <ObjectCalculation></ObjectCalculation>
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                )} 
                                {
                                    objCalcDataList.length <= 0?
                                        <div className="text-center" style={{width:'100%'}}>
                                            <img src={emptyIcon} /><br/>
                                            <small>{translation.noDataAvail[locale]}</small>
                                        </div>
                                    :''
                                }
                                
                                {/* <h5 className="no-data-text text-center">No Product in Cart!</h5> */}
                            
                            {/* <div>
                                <div>
                                    <ul className="pagination custom-pagination">
                                        { pageNumbers.map((number) => (
                                            <li key={number} className="page-item">
                                                <a href="#" className="page-link  custom-page-link" onClick={(e) => {paginate(e, number)}}>{number}</a> 
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="text-center mt-2">
                                    {productInCart.length >=1?
                                            <Button className="btn-md" >Check out</Button>
                                        : ''
                                    }
                                </div>
                            </div> */}
                        </ul>
                       
                </ul> 
            </div>  
        </>
    )
}

export default ObjCalcList
