import React, {useState, useContext, useEffect} from 'react'
import { Form, Input, Label, FormGroup, Col, Button } from 'reactstrap';
import './../clientStyle.css'
import {AppContext} from '../../../contexts/AppContext'
import { withRouter } from 'react-router-dom'
import numeral from 'numeral'
import ProcessingSpinner from '../../common/ProcessingSpinner'

import {Typeahead} from 'react-bootstrap-typeahead'; // ES2015
import  Autocomplete  from 'react-autocomplete';


import 'react-bootstrap-typeahead/css/Typeahead.css';

const ObjectInfo = withRouter(({history,match}) => {

    const {dispatch, objectInfo, SubmitObject, HSRegion, translation, locale, isSaveClientDetails}= useContext(AppContext)

    const [multi, setMulti] = useState(false)
    
    const [yearEr, setYearEr] = useState(false)
    const checkBuildYear = (e, fieldName) => {
        let currentYear = new Date().getFullYear()
        let inVal = e.currentTarget.value
        if(inVal.match(/^[0-9]+$/) !== null || inVal ===''){
            
            if(inVal.length <= 4){
                
                dispatch({objectInfo:{...objectInfo, [fieldName]:numeral(inVal).value()}})
                if(inVal.length == 4 || inVal.length == 0){
                    setYearEr(false)
                    dispatch({saveClientErr:false})
                }else{
                    setYearEr(true)
                    dispatch({saveClientErr:true})
                }
            }
        }
    }
    // const [checker, setChecker] = useState('')
    useEffect((e)=>{
        if((typeof HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ) != 'object') || (HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ).length == 1)){
            if(objectInfo.PLZ != ''){
                dispatch({objectInfo:{...objectInfo, Ort:HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ)[0].Ort}})} 

        }else{
            if((typeof HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ) != 'object') && HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ).length > 1 || objectInfo.PLZ == ''){
                dispatch({objectInfo:{...objectInfo, Ort:''}}) 
            }
            
            
        }
    }, [isSaveClientDetails, objectInfo.PLZ, objectInfo.Ort])
    const [Verkehrswert, setVerkehrswert] = useState(false)
    
    const emptyOrt = () => {
        
        if((typeof HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ) == 'object') && HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ).length != 1){
            dispatch({objectInfo:{...objectInfo, Ort:''}}) 
        }
    }

    //for PLZ and Ort auto select Function
   

    var inputPropsStyle = { style:{
        width: '100%',
        height: 'calc(1.5em + .75rem + 2px)',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#495057',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'
    }}
    var menuCustomStyle = {
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        background: 'rgba(255, 255, 255, 0.9)',
        fontSize: '16px',
        position: 'fixed',
        overflow: 'auto',
        zIndex:'99999',
        maxHeight: '30%', // TODO: don't cheat, let it flow to the bottom
    }




    return (
        <>
            <Form onSubmit={(e) => { SubmitObject(e, match.params.id) }}>
               
                <div className=" page-cform ">
                    <FormGroup row>
                        <Label for="Objektart" sm={4}>{translation.obj_typ[locale]}</Label>
                        <Col sm={8}>
                            <Input required id="Objektart" type="select" name="select" 
                                value={objectInfo.Objektart}
                                onChange={(e) => {dispatch({objectInfo:{...objectInfo, Objektart:e.currentTarget.value }})}}>
                                    <option value="Einfamilienhaus">
                                        {translation.sin_fam_liv_hous[locale]}
                                    </option>

                                    <option value="Mehrfamilienhaus">
                                        {translation.mul_fam_dwe[locale]}
                                    </option>
                                    <option value="Stockwerkeigentum">
                                        {translation.con_apar[locale]}
                                    </option>
                                    <option value="Büro">
                                        {translation.off_buil[locale]}
                                    </option>
                                    <option value="Gewerbe">
                                        {translation.com_buil[locale]}
                                    </option>
                                    <option value="Industrie">
                                        {translation.ind_buil[locale]}
                                    </option>
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Nutzung" sm={4}>{translation.usage[locale]}</Label>
                        <Col sm={8}>
                            <Input required id="Nutzung" type="select" name="Nutzung" 
                                value={objectInfo.Nutzung}
                                onChange={(e) => 
                                    {dispatch({objectInfo:{...objectInfo, Nutzung:e.currentTarget.value }})}
                                }>
                                <option value="selbstgenutzt">{translation.own_occ[locale]}</option>
                                <option value="fremdgenutzt">{translation.use_by_oth[locale]}</option>
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Verkehrswert" sm={4}>{translation.mar_val[locale]}</Label>
                        <Col sm="8">
                            <Input required type="text" name="Verkehrswert" 
                                value={ Verkehrswert?objectInfo.Verkehrswert: numeral(objectInfo.Verkehrswert).format("0,0").split(',').join("'")}
                                onFocus= {() => {setVerkehrswert(true)}}
                                onBlur = {() => {setVerkehrswert(false)}}
                                onChange={(e) => {
                                    if(objectInfo.Verkehrswert !== 0 || e.currentTarget.value !== 0){
                                        dispatch({objectInfo:{...objectInfo, Verkehrswert:numeral(e.currentTarget.value).value()}})
                                    }
                                }
                            }/>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Adresse" sm={4}>{translation.address[locale]} :</Label>
                        <Col sm="8">
                            <Input required type="text" name="Adresse" 
                                value={objectInfo.Adresse}
                                onChange={(e) => {dispatch({objectInfo:{...objectInfo, Adresse:e.currentTarget.value }})}
                            }/>
                        </Col>
                        <Label for="PLZ" sm={4}>{translation.posCode[locale]}:</Label>
                        <Col sm="8">
                            <Autocomplete style={{width:'100%'}}
                                getItemValue={(item) => item.PLZ}
                                shouldItemRender={(item, value) => item.PLZ.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                items={HSRegion}
                                renderItem={(item, highlighted) =>
                                    <div 
                                        key={item.id}
                                        style={{ backgroundColor: highlighted ? '#eee' : 'white', padding:'5px'}}>
                                            {item.PLZ}
                                    </div>
                                }
                                wrapperProps={{style:{display: 'block'}}}
                                inputProps={inputPropsStyle}
                                menuStyle={menuCustomStyle}
                                value={
                                    objectInfo.PLZ
                                }
                                onChange={(e) => {
                                        dispatch({objectInfo: {...objectInfo, PLZ:e.currentTarget.value}})
                                    }
                                }
                                onSelect={(val) => {
                                        emptyOrt()
                                        dispatch({objectInfo: {...objectInfo, PLZ:val}})
                                        
                                    }
                                }
                                
                            />
                        </Col>


                            
                        
                        <Label for="Ort" sm={4}>{translation.city[locale]}:</Label>
                        <Col sm="8">

                        <Autocomplete style={{width:'100%'}}
                            getItemValue={(item) => item.Ort}
                            shouldItemRender={(item, value) => item.Ort.toLowerCase().indexOf(value.toLowerCase()) > -1}
                            items={objectInfo.PLZ==''? HSRegion:HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ)}
                            renderItem={(item, highlighted) =>
                                <div 
                                    key={item.id}
                                    style={{ backgroundColor: highlighted ? '#eee' : 'white', padding:'5px'}}>
                                        {item.Ort}
                                </div>
                            }
                            wrapperProps={{style:{display: 'block'}}}
                            inputProps={{
                                ...inputPropsStyle, 
                                disabled:(typeof HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ) != 'object') || (HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ).length == 1)?'disabled':''
                            }}
                            menuStyle={menuCustomStyle}
                            value={
                                objectInfo.Ort
                            }
                            onChange={(e) => {
                                    dispatch({objectInfo: {...objectInfo, Ort:e.currentTarget.value}})
                                }
                            }
                            onSelect={(val) => {
                                    dispatch({objectInfo: {...objectInfo, Ort:val}})
                                }
                            }
                        />
                            
                            {/* {
                                // (HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ).map(e => e.Ort)).length != 1 ?
                                objectInfo.PLZ=='' || objectInfo.PLZ==undefined?
                                
                                <Typeahead
                                    id="typeahead-Ort"
                                    labelKey="name"
                                    multiple={false}
                                    options={ HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ).map(e => e.Ort)}
                                    onChange={(e)=>{
                                        if(e.length > 0){
                                            changeOrtVal(e)
                                        }
                                    }}
                                    onInputChange={(e)=>{
                                        changeOrtVal(e)
                                    }}
                                    selected={[newSelected]}
                                    // selected={objectInfo.PLZ!='' || objectInfo.PLZ!=undefined?[newSelected] :[]}
                                />
                                
                            :(HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ).map(e => e.Ort)).length > 1 ?

                                <Typeahead
                                    id="typeahead-Ort"
                                    labelKey="name"
                                    multiple={false}
                                    options={  HSRegion.filter(plzCode =>  plzCode.PLZ == objectInfo.PLZ).map(e => e.Ort)}
                                    onChange={(e)=>{
                                        if(e.length > 0){
                                            changeOrtVal(e)
                                        }
                                    }}
                                    onInputChange={(e)=>{
                                        changeOrtVal(e)
                                    }}
                                    // selected={objectInfo.PLZ!='' || objectInfo.PLZ!=undefined?[newSelected] :[]}
                                />
                            :
                                <Typeahead
                                id="typeahead-Ort"
                                labelKey="name"
                                multiple={false}
                                options={ [newSelected]}
                                onChange={(e)=>{
                                    if(e.length > 0){
                                        changeOrtVal(e)
                                    }
                                }}
                                onInputChange={(e)=>{
                                    changeOrtVal(e)
                                }}
                                selected={[newSelected]}
                                // selected={objectInfo.PLZ!='' || objectInfo.PLZ!=undefined?[newSelected] :[]}
                            />
                            } */}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Baujahr" sm={4}>{translation.yr_of_buil[locale]}:</Label>
                        <Col sm="8">
                            <Input required id="Baujahr" type="text" name="Baujahr"  
                                value={objectInfo.Baujahr}
                                required
                                style={{
                                    border:yearEr?'1px solid red':'1px solid #ced4da',
                                    boxShadow:yearEr?'0 0 0 0.2rem rgba(241, 123, 123, 0.25)':''
                                }}
                                onChange={(e) => {
                                    checkBuildYear(e, 'Baujahr')
                                }
                            } />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={4}></Label>
                        <Label for="DauerBaurecht" sm={3} check>{translation.lux_obj[locale]}:</Label>
                        <Col sm="1">
                            <Input type="checkbox" name="Luxusobjekt" checked={parseInt(objectInfo.Luxusobjekt) === 1?'checked':''}
                                disabled={objectInfo.Objektart === 'Einfamilienhaus' || objectInfo.Objektart === 'Stockwerkeigentum'? '':'disabled'} 
                                onChange={(e)=>{
                                    dispatch({objectInfo:{...objectInfo, Luxusobjekt:(e.currentTarget.checked)===true?1:0 }})
                                }}
                            />{' '}
                        </Col>
                        <Label for="Zentrumslage" sm={3} check>{translation.cen_loc[locale]}:</Label>
                        <Col sm="1">
                            <Input type="checkbox" name="Zentrumslage" checked={parseInt(objectInfo.Zentrumslage) === 1?'checked':''}
                                disabled={objectInfo.Objektart === 'Büro' || objectInfo.Objektart === 'Gewerbe'? '':'disabled'}
                                onChange={(e)=>{
                                    dispatch({objectInfo:{...objectInfo, Zentrumslage:(e.currentTarget.checked)===true?1:0 }})
                                }}
                            />{' '}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Baurecht" sm={4}>{translation.buil_righ[locale]}:</Label>
                        <Col sm="8">
                            <Input required id="baurecht" type="select" name="Baurecht" id="baurecht" 
                                value={objectInfo.baurecht}
                                onChange={(e) =>  { dispatch({objectInfo:{...objectInfo, baurecht:e.currentTarget.value }}) }}>
                                    <option value="Nien">{translation.no[locale]}</option>
                                    <option value="Ja">{translation.yes[locale]}</option>
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Baurechtszins" sm={4}>{translation.buil_lea[locale]}:</Label>
                        <Col sm="8">
                            <Input required type="text" name="Baurechtszins:" 
                                disabled={
                                    objectInfo.baurecht == 'Nien'?'disabled':''
                                } 
                                value={objectInfo.baurecht == 'Nien'?'': objectInfo.Baurechtszins}
                                onChange={(e) => {
                                    if(e.currentTarget.value.match(/^[0-9]+$/) !== null || e.currentTarget.value ===''){
                                        dispatch({objectInfo:{...objectInfo, Baurechtszins:e.currentTarget.value }})
                                    }
                                }
                            } />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="DauerBaurecht" sm={4}>{translation.dur_of_buil_righ[locale]}:</Label>
                        <Col sm="8">
                            <Input required type="text" name="DauerBaurecht"
                                disabled={
                                    objectInfo.baurecht == 'Nien'?'disabled':''
                                } 
                                value={objectInfo.baurecht == 'Nien'?'':objectInfo.DauerBaurecht}
                                onChange={(e) => {
                                    if(e.currentTarget.value.match(/^[0-9]+$/) !== null || e.currentTarget.value ===''){
                                        dispatch({objectInfo:{...objectInfo, DauerBaurecht:e.currentTarget.value }})
                                    }
                                }
                            } />
                        </Col>
                    </FormGroup>
                </div>
                <div className="text-right container">
                    <Button className="modal-btn" disabled={isSaveClientDetails?'disabled':''}>{translation.submit[locale]}</Button>
                    {isSaveClientDetails? 
                         <div style={{float:'right'}}>
                            <ProcessingSpinner/>
                        </div>:''
                     }
                </div>
            </Form>
            
        </>
    )
})

export default ObjectInfo
