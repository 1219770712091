import React, {useState, useEffect, useContext} from 'react'
import {Input, Label, FormGroup, Col, Button, Form } from 'reactstrap';
import './clientStyle.css'
import {AppContext} from '../../contexts/AppContext'
import numeral from 'numeral'
import ProcessingSpinner from '../common/ProcessingSpinner'


function CustomerInfo(props) {

    const {dispatch, clientDetails, evnCli, submitDetails, translation, locale, isSaveClientDetails}= useContext(AppContext)
    const [percentAl, setPercentAl] = useState(false)

    const Kun = clientDetails

    // useEffect(()=>{
    //     console.log(Kun) 
    // }, [Kun])

    useEffect(() => {
        // checkIfSave(props.id)
        if(parseInt(Kun.inputPercVarKommenCol1) <= 50 && parseInt(Kun.inputPercVarKommenCol2) <= 50){
            const calcIVKCol1 = Kun.inputVarKommenCol1 * Kun.inputPercVarKommenCol1 / 100
            const calcIVKCol2 = Kun.inputVarKommenCol2 * Kun.inputPercVarKommenCol2 / 100

            
            // TotalEinkommenSum
            const TotEinkom = [
                Kun.FixkomKreI== null?0:Kun.FixkomKreI,
                Kun.FixkomKreII== null?0:Kun.FixkomKreII,
                Kun.CalcInputVarKomI== null?0:Kun.CalcInputVarKomI,
                Kun.CalcInputVarKomII== null?0:Kun.CalcInputVarKomII,
                Kun.NebkomKNI== null?0:Kun.NebkomKNI,
                Kun.NebkomKNII== null?0:Kun.NebkomKNII,
                Kun.bersLie== null?0:Kun.bersLie,
            ]
            
            const convertTotEinkom = TotEinkom.map(parseFloat)
            const TotalEinkommenSum = convertTotEinkom.reduce((a, b) => a + b, 0)

            // TotBelastungSum
            const TotBel = [
                Kun.bersLieMinus== null?0:Kun.bersLieMinus,
                Kun.BelLeasings== null?0:Kun.BelLeasings,
                Kun.Alimente== null?0:Kun.Alimente,
            ]
            const convertTotBel = TotBel.map(parseFloat)
            const TotBelastungSum = convertTotBel.reduce((a, b) => a + b, 0)
            
            const computeVerEinkom = Kun.TotalEinkommen - Kun.TotBelastung
            
            // clientDetails.VerEinkom == clientDetails.VerEinkomcal ?dispatch({isSave:true}):dispatch({isSave:false})

            dispatch({clientDetails:{...clientDetails, CalcInputVarKomI:calcIVKCol1, CalcInputVarKomII:calcIVKCol2, TotalEinkommen:TotalEinkommenSum, TotBelastung:TotBelastungSum, VerEinkom:computeVerEinkom}})

        }else{
            setPercentAl(true)
        }
    }, [
        
        Kun.inputVarKommenCol1, 
        Kun.inputPercVarKommenCol1, 
        Kun.inputVarKommenCol2, 
        Kun.inputPercVarKommenCol2, 
        Kun.TotalEinkommen,
        Kun.FixkomKreI,
        Kun.FixkomKreII,
        Kun.CalcInputVarKomI,
        Kun.CalcInputVarKomII,
        Kun.NebkomKNI,
        Kun.NebkomKNII,
        Kun.bersLie,
        Kun.bersLieMinus,
        Kun.BelLeasings,
        Kun.Alimente,
        Kun.TotBelastung,
        Kun.VerEinkom,
        isSaveClientDetails
    ])
    const checkLetter = (e, kunName) => {
        if(e.currentTarget.value.match(/\d+/g) == null || e.currentTarget.value === ''){
            dispatch({clientDetails:{...clientDetails, [kunName]:e.currentTarget.value}})
        }else{
            return false
        }
    }
    const checkNumber = (e, nmbr) => {
        if(e.currentTarget.value.match(/^[0-9]+$/) || e.currentTarget.value === ''){
            dispatch({clientDetails:{...clientDetails, [nmbr]:e.currentTarget.value}})
        }else{
            return false
        }
    }
    const [yearEr, setYearEr] = useState(false)
    const [yearEr2, setYearEr2] = useState(false)

    const checkYear = (e, fieldName) => {
        let currentYear = new Date().getFullYear()
        let inVal = e.currentTarget.value
        if(inVal.match(/^[0-9]+$/)  || inVal == ''){
            
            if(inVal.length <= 4){
                
                dispatch({clientDetails:{...clientDetails, [fieldName]:numeral(inVal).value()}})
                if(inVal > 1800 && inVal < currentYear && inVal.length == 4 || inVal.length == 0){
                    if(fieldName == 'JahrgangI'){setYearEr(false)}
                    if(fieldName == 'JahrgangII'){setYearEr2(false)}
                    // if(inVal.length == 0){
                    //     dispatch({saveClientErr:true})
                    // }else{
                    //     dispatch({saveClientErr:false})
                    // }
                }else{
                    if(fieldName == 'JahrgangI'){setYearEr(true)}
                    if(fieldName == 'JahrgangII'){setYearEr2(true)}
                    dispatch({saveClientErr:true})
                }
            }
        }
    }
    const [FixkomI, setFixkomI] = useState(false)
    const [FixkomII, setFixkomII] = useState(false)
    const [VarKommenCol1, setVarKommenCol1] = useState(false)
    const [VarKommenCol2, setVarKommenCol2] = useState(false)
    const [NebkomKNI, setNebkomKNI] = useState(false)
    const [NebkomKNII, setNebkomKNII] = useState(false)
    const [bersLie, setbersLie] = useState(false)
    const [ bersLieMinus, setbersLieMinus] = useState(false)
    const [BelLeasings, setBelLeasings] = useState(false)
    const [Alimente, setAlimente] = useState(false)
    

    return (
        <>
            <Form onSubmit={(e) => { submitDetails(e) }}>
            <div className="page-cform mb-1">
                <FormGroup row>
                    <Label for="Name" sm={5}>{translation.CusNameI[locale]}</Label>
                    <Col sm={6}>
                        <Input required type="text" name="Name"
                            value={clientDetails.KundennameI}
                            onChange={(e) => {
                                checkLetter(e, 'KundennameI')
                            }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Name" sm={5}>{translation.CusNameII[locale]}</Label>
                    <Col sm={6}>
                        <Input type="text" name="Name"
                            value={clientDetails.KundennameII}
                            onChange={(e) => {
                                checkLetter(e, 'KundennameII')
                            }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Age" sm={5}>{translation.address[locale]}</Label>
                    <Col sm={6}>
                        <Input type="text" name="Age"
                            value={clientDetails.Adresse}
                            onChange={(e) => dispatch({clientDetails:{...clientDetails, Adresse:e.currentTarget.value}})}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Gender" sm={5}>{translation.posCode[locale]}</Label>
                    <Col sm={6}>
                        <Input type="text" name="Name" 
                            value={clientDetails.PLZWohnort}
                            onChange={(e) => dispatch({clientDetails:{...clientDetails, PLZWohnort:e.currentTarget.value}})}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Gender" sm={5}>{translation.partnerNo[locale]}</Label>
                    <Col sm={6}>
                        <Input type="text" name="Name" 
                            value={clientDetails.PartnerNr}
                            onChange={(e) => checkNumber(e, 'PartnerNr')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm="5"></Col>
                    <Col sm="3" className="text-center">
                        {translation.borOne[locale]}
                    </Col>
                    <Col sm="3" className="text-center">
                        {translation.borTwo[locale]}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="Jahrgang" sm={5}>{translation.yrOfBirth[locale]}</Label>
                    <Col sm="3">
                        <Input type="text" name="Jahrgang"
                            required
                            value={clientDetails.JahrgangI}
                            style={{
                                border:yearEr?'1px solid red':'',
                                boxShadow:yearEr?'0 0 0 0.2rem rgba(241, 123, 123, 0.25)':''
                            }}
                            onChange={(e) => {
                                checkYear(e, 'JahrgangI')
                            }}
                        />
                    </Col>
                    <Col sm="3">
                        <Input type="text" name="Jahrgang" 
                            value={clientDetails.JahrgangII}
                            style={{
                                border:yearEr2?'1px solid red':'',
                                boxShadow:yearEr2?'0 0 0 0.2rem rgba(241, 123, 123, 0.25)':''
                            }}
                            onChange={(e) => {
                                checkYear(e, 'JahrgangII')
                            }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="FixeinkommenKreditnehmerI" sm={5}>{translation.fix_in_bor[locale]}</Label>
                    <Col sm="3">
                        <Input type="text" name="FixkomKreI" 
                            value={FixkomI? clientDetails.FixkomKreI:numeral(clientDetails.FixkomKreI).format("0,0").split(',').join("'")}
                            onFocus= {() => {setFixkomI(true)}}
                            onBlur = {() => {setFixkomI(false)}}
                            onChange={(e) => {
                                evnCli(e, 'FixkomKreI')
                            }}
                        />
                    </Col>
                    <Col sm="3">
                        <Input type="text" name="FixkomKreCol2I" 
                            value={FixkomII?clientDetails.FixkomKreII:numeral(clientDetails.FixkomKreII).format("0,0").split(',').join("'")}
                            onFocus= {() => {setFixkomII(true)}}
                            onBlur = {() => {setFixkomII(false)}}
                            onChange={(e) => {
                                evnCli(e, 'FixkomKreII')
                            }}
                        />
                    </Col>
                    
                    <Label sm={5}>{translation.var_in_bor[locale]}</Label>
                    <Col sm="3" className="ta-4 text-al">
                        <label className="fl">Fr.</label> 
                        <b className="res-val-8">{ 
                            `${
                                numeral(clientDetails.CalcInputVarKomI).format("0,0.00").split(',').join("'")
                            }`
                        }
                        </b>
                    </Col>
                    <Col sm="3" className="ta-4 text-al">
                        <label className="fl">Fr.</label> 
                        <b className="res-val-8">{ 
                            `${
                                numeral(clientDetails.CalcInputVarKomII).format("0,0.00").split(',').join("'")
                            }`
                        }
                        </b>
                    </Col>
                            <Label for="inputVarKommen" sm={5}></Label>
                            <Col sm="3">
                                <Input type="text" name="inputVarKommenI" 
                                    required
                                    value={VarKommenCol1? clientDetails.inputVarKommenCol1: numeral(clientDetails.inputVarKommenCol1).format("0,0").split(',').join("'")}
                                    placeholder="Bonus"
                                    onFocus= {() => {setVarKommenCol1(true)}}
                                    onBlur = {() => {setVarKommenCol1(false)}}
                                    onChange={(e) => {
                                        evnCli(e, 'inputVarKommenCol1')
                                    }}/>
                            </Col>
                            <Col sm="3">
                                <Input type="text" name="inputVarKommenCol2I" 
                                    value={ VarKommenCol2? clientDetails.inputVarKommenCol2:numeral(clientDetails.inputVarKommenCol2).format("0,0").split(',').join("'")}
                                    placeholder="Bonus"
                                    onFocus= {() => {setVarKommenCol2(true)}}
                                    onBlur = {() => {setVarKommenCol2(false)}}
                                    onChange={(e) => {
                                        evnCli(e, 'inputVarKommenCol2')
                                    }}
                                />
                            </Col>
                            <Label for="inputVarKommen" sm={5}></Label>
                            <Col sm="3">
                                <Input type="text" name="inputVarKommenI"  placeholder={translation.percentage[locale]}
    
                                    value={clientDetails.inputPercVarKommenCol1 == 0?'': clientDetails.inputPercVarKommenCol1}
                                    onChange={(e) => {
                                        if(e.currentTarget.value <= 50 ){
                                            dispatch({clientDetails:{...clientDetails, inputPercVarKommenCol1:e.currentTarget.value}})
                                        }
                                    }}
                                    style={{
                                        borderColor: percentAl && clientDetails.inputPercVarKommenCol1 > 50?
                                            '#f7255d66':'', 
                                        boxShadow: percentAl && clientDetails.inputPercVarKommenCol1 > 50? 
                                            '0 0 0 0.2rem rgba(241, 123, 123, 0.25)':''}}
                                />
                            </Col>
                            <Col sm="3">
                                <Input type="text" name="inputVarKommenII" placeholder={translation.percentage[locale]}  
    
                                    value={clientDetails.inputPercVarKommenCol2 == 0?'': clientDetails.inputPercVarKommenCol2}
                                    onChange={(e) => {
                                        if(e.currentTarget.value <= 50 ){
                                            var curTarget = e.currentTarget.value == ''?0:e.currentTarget.value
                                            dispatch({clientDetails:{...clientDetails, inputPercVarKommenCol2:curTarget}})
                                        }
                                    }}
                                    style={{
                                        borderColor: percentAl && clientDetails.inputPercVarKommenCol2 > 50? 
                                            '#f7255d66':'', 
                                        boxShadow: percentAl && clientDetails.inputPercVarKommenCol2 > 50? 
                                            '0 0 0 0.2rem rgba(241, 123, 123, 0.25)':''
                                    }}
                                />
                                
                            </Col>
                            <Label for="inputVarKommen" sm={5}></Label>
                            <Col sm="6" className="text-center">
                                {/* <p style={{
                                    color: clientDetails.inputPercVarKommenCol1 || clientDetails.inputPercVarKommenCol2 > 50?'rgb(251, 74, 74)':'', 
                                    display: clientDetails.inputPercVarKommenCol1 || clientDetails.inputPercVarKommenCol2 > 50 ?'initial':'none'}}>Please Input 50% below</p> */}
                            </Col>
                            
                    <Label for="NebeneinkommenKNI" sm={5}>{translation.add_in_bor[locale]}</Label>
                    <Col sm="3">
                        <Input type="text" name="NebkomKNI" 
                            value={NebkomKNI? clientDetails.NebkomKNI:numeral(clientDetails.NebkomKNI).format("0,0").split(',').join("'")}
                            onFocus= {() => {setNebkomKNI(true)}}
                            onBlur = {() => {setNebkomKNI(false)}}
                            onChange={(e) => {  
                                evnCli(e, 'NebkomKNI')
                            }}
                        />
                    </Col>
                    <Col sm="3">
                        <Input type="text" name="NebkomKNCol2I" 
                            value={NebkomKNII? clientDetails.NebkomKNII : numeral(clientDetails.NebkomKNII).format("0,0").split(',').join("'")}
                            onFocus= {() => {setNebkomKNII(true)}}
                            onBlur = {() => {setNebkomKNII(false)}}
                            onChange={(e) => {
                                evnCli(e, 'NebkomKNII')
                            }}
                        />
                    </Col>
                    
                    <Label for="bersLie" sm={5}>{translation.sur_fr_re_est[locale]}</Label>
                    <Col sm="6">
                        <Input type="text" name="bersLie" 
                            value={bersLie?clientDetails.bersLie :numeral(clientDetails.bersLie).format("0,0").split(',').join("'")}
                            onFocus= {() => {setbersLie(true)}}
                            onBlur = {() => {setbersLie(false)}}
                            onChange={(e) => {
                                evnCli(e, 'bersLie')
                            }}
                        />
                    </Col>
                    <Label for="TotalEinkommen" sm={5}>{translation.tot_in[locale]}</Label>
                    <Col sm="6" className="ta-4 text-al">
                        <label className="fl">Fr.</label> 
                        <b className="res-val-8">{ 
                            `${
                                numeral(clientDetails.TotalEinkommen).format("0,0.00").split(',').join("'")
                            }`
                        }
                        </b>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="bersLieMinus" sm={5}>{translation.def_fr_re_est[locale]}</Label>
                    <Col sm="6">
                        <Input type="text" name="bersLieMinus" 
                            value={bersLieMinus? clientDetails.bersLieMinus :numeral(clientDetails.bersLieMinus).format("0,0").split(',').join("'")}
                            onFocus= {() => {setbersLieMinus(true)}}
                            onBlur = {() => {setbersLieMinus(false)}}
                            onChange={(e) => {
                                evnCli(e, 'bersLieMinus')
                            }}
                        />
                    </Col>

                        <Label for="BelLeasings" sm={5}>{translation.lia_fr_leas[locale]}</Label>
                    <Col sm="6">
                        <Input type="text" name="BelLeasings" 
                            value={BelLeasings? clientDetails.BelLeasings :numeral(clientDetails.BelLeasings).format("0,0").split(',').join("'")}
                            onFocus= {() => {setBelLeasings(true)}}
                            onBlur = {() => {setBelLeasings(false)}}
                            onChange={(e) => {
                                evnCli(e, 'BelLeasings')
                            }}
                        />
                    </Col>

                        <Label for="Alimente" sm={5}>{translation.ali_or_simi[locale]}</Label>
                    <Col sm="6">
                        <Input type="text" name="Alimente" 
                            value={Alimente?clientDetails.Alimente:numeral(clientDetails.Alimente).format("0,0").split(',').join("'")}
                            onFocus= {() => {setAlimente(true)}}
                            onBlur = {() => {setAlimente(false)}}
                            onChange={(e) => {
                                evnCli(e, 'Alimente')
                            }}
                        />
                    </Col>

                    <Label for="Alimente" sm={5}>{translation.tot_lia[locale]}</Label>
                    <Col sm="6" className="ta-4 text-al">
                        <label className="fl">Fr.</label> 
                        <b className="res-val-8">{ 
                            `${
                                numeral(clientDetails.TotBelastung).format("0,0,00").split(',').join("'")
                            }`
                        }
                        </b>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="VerEinkom" sm={5}>{translation.dis_in[locale]}</Label>
                    <Col sm="6" className="ta-4 text-al">
                        <label className="fl">Fr.</label> 
                        <b className="res-val-8">{ 
                            `${
                                numeral(clientDetails.VerEinkom).format("0,0.00").split(',').join("'")
                            }`
                        }
                        </b>
                    </Col>
                </FormGroup>
            </div>
            <div className="text-right" style={{borderTop: '1px solid #e8ebee', paddingTop:'10px'}}>
                
                <Button className="modal-btn" disabled={isSaveClientDetails?'disabled':''} style={{marginRight:'40px'}} type="submit">{translation.save[locale]}</Button>
                {isSaveClientDetails?
                    <div style={{float:'right'}}>
                        <ProcessingSpinner/>
                    </div>:''
                }
            </div>
            </Form>
        </>
    )
}

export default CustomerInfo
