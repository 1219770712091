import React, { useContext, useState } from 'react'
import { AppContext } from '../../contexts/AppContext'



const DemoApp = () => {

    const { locale, translation } = useContext(AppContext)

    var demoStatLs = localStorage.getItem('demoStatus')
    const [demoClose, setDemoClose] = useState(demoStatLs? true:false)

   
    
    return (
        <>
        <div className="demo__header">
            <div className="demo__text">
                {translation.demoText[locale]}
            </div>
        </div>
        </>
    )
}

export default DemoApp
