import React, { useContext, Fragment, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import './App.css'

// 3rd party


// Common
import Loading from './components/common/Loading'
import ComponentNotFound  from './components/error/ComponentNotFound'
import MainNavbar from './components/common/MainNavbar'
import SideNav from './components/common/SideNav'

// Contexts
import { AuthContext } from './contexts/AuthContext'

// Components
import Login  from './components/login/Login'
import MasterData  from './components/master-data/MasterData'
import Client from './components/client/ClientInfo';
import Calculation from './components/client/calculation/Calculation';

import { AppContext } from './contexts/AppContext';


const App = () => {
   const {dispatch, navStat} = useContext(AppContext)
   const Auth = useContext(AuthContext)
   const [sideNavIsOpen, toggleSideNav] = useState(true)
   const [showLinkTitle, toggleshowLinkTitle] = useState(true)
    
   return (
         <Fragment>
            
            {
               Auth.isLoading? <Loading /> :
               <Router>
                  {
                     Auth.isOnline? 
                        <>
                           <MainNavbar toggleSideNav={toggleSideNav} sideNavIsOpen={sideNavIsOpen} toggleshowLinkTitle={toggleshowLinkTitle} showLinkTitle={showLinkTitle} />
                           <SideNav sideNavIsOpen={sideNavIsOpen} showLinkTitle={showLinkTitle} /> 
                        
                        </>
                        : ''
                  }
                  
                     <main className={ Auth.isOnline == false && navStat == true || Auth.isOnline == false && navStat == false? '': Auth.isOnline == true && navStat === true?'main-box':Auth.isOnline == true && navStat === false?'main-box-full':''}>
                        
                        <Switch>
                           
                           <Route exact path="/" render={ props => 

                              Auth.isOnline? (<MasterData />) : (<Login />)
                           } />

                           <Route exact path="/login" render={ props => 
                              Auth.isOnline? (<Redirect to="/masterdata" />) : (<Login />)
                           } />
                           
                           <PrivateRoute exact path="/masterdata">
                              <MasterData />
                           </PrivateRoute>

                           <PrivateRoute exact path="/client">
                              <Client />
                           </PrivateRoute>
                           <PrivateRoute exact path="/calculate/:id/:progress">
                              <Calculation />
                           </PrivateRoute>
                           

                           <Route component={ComponentNotFound} />
                           
                        </Switch>
                     </main>
                  
               
               </Router>
            }
         </Fragment>
   )
}

export default App



