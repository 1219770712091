import React, {useEffect, useContext, useState } from 'react'
import {Form, Input} from 'reactstrap'
import {AppContext} from '../../contexts/AppContext'
import Page from '../common/Page'
import './style.css'
import Axios from 'axios'
import { Doughnut } from 'react-chartjs-2';
import SummaryDetails from './summary/SummaryDetails'





function MasterData() {
   const {dispatch, clientData, ObjektangabenList, dashboard} = useContext(AppContext)
  
   

   // useEffect(() => {
   //    console.log(objCount)
   // }, [objCount])

   useEffect(() => {
      Axios.get('/api/objectdata')
      .then((res) => {
         dispatch({ObjektangabenList:res.data})
         
         statusTotal(res.data)
         

      })
      .catch((err) => {
         console.log(err)
      })
   }, [])





   const statusTotal = (objStatData) => {

      
      
      let objAct = objStatData.filter((obj) => (obj.status == 'Active'))
      let objInAct = objStatData.filter((obj) => (obj.status == 'Inactive'))

      let objInProg = objStatData.filter((obj) => (obj.progress == 'In progress'))
      let objOff = objStatData.filter((obj) => (obj.progress == 'Offered'))
      let objAcc = objStatData.filter((obj) => (obj.progress == 'Accepted'))
      let objDis = objStatData.filter((obj) => (obj.progress == 'Dismissed'))


      let sumActItem = 0
      objAct.forEach(element => {
         sumActItem += parseInt(element.Verkehrswert)
      });  
      let sumInActItem = 0
      objInAct.forEach(element => {
         sumInActItem += parseInt(element.Verkehrswert)
      }); 

      let sumInProgItem = 0
      objInProg.forEach(element => {
         sumInProgItem += parseInt(element.Verkehrswert)
      });  
      let sumOffItem = 0
      objOff.forEach(element => {
         sumOffItem += parseInt(element.Verkehrswert)
      });  
      let sumAccItem = 0
      objAcc.forEach(element => {
         sumAccItem += parseInt(element.Verkehrswert)
      });  
      let sumDisItem = 0
      objDis.forEach(element => {
         sumDisItem += parseInt(element.Verkehrswert)
      });  


      dispatch({dashboard:{...dashboard, 

         objLength:objStatData.filter((obj) => (obj.progress == 'Accepted')).length,
         
         objOpen:objStatData.filter((obj) => (obj.progress == 'In progress')).length,
         objOffer:objStatData.filter((obj) => (obj.progress == 'Offered')).length,
         objAccepted:objStatData.filter((obj) => (obj.progress == 'Accepted')).length,
         objDismissed:objStatData.filter((obj) => (obj.progress == 'Dismissed')).length,

         objActive:objStatData.filter((obj) => (obj.status == 'Active')).length,
         objInActive:objStatData.filter((obj) => (obj.status == 'Inactive')).length,

         // count by verkerswert of active and inactive status
         objActVerk: sumActItem,
         objInActVerk: sumInActItem,

         objOpenVerk:sumInProgItem,
         objOfferVerk:sumOffItem,
         objAcceptedVerk:sumAccItem,
         objDismissedVerk:sumDisItem,

      }})
   }

  
   return (
      <Page title="Dashboard" description="" icon="fas fa-list fa-lg">
         <SummaryDetails></SummaryDetails>
      </Page>
   )
}

export default MasterData
