import React, {useState, useEffect, useContext} from 'react'
import {Input, Label, FormGroup, Col } from 'reactstrap';
import './../clientStyle.css'
import {AppContext} from '../../../contexts/AppContext'
import numeral from 'numeral'


function CustomerInfo(props) {

    const {dispatch, KundeGui, evn, clientDetails, checkIfSave}= useContext(AppContext)
    const [percentAl, setPercentAl] = useState(false)

    const Kun = KundeGui

    // useEffect(()=>{
    //     console.log(Kun) 
    // }, [Kun])

    useEffect(() => {
        // checkIfSave(props.id)
        if(parseInt(Kun.inputPercVarKommenCol1) <= 50 && parseInt(Kun.inputPercVarKommenCol2) <= 50){
            const calcIVKCol1 = Kun.inputVarKommenCol1 * Kun.inputPercVarKommenCol1 / 100
            const calcIVKCol2 = Kun.inputVarKommenCol2 * Kun.inputPercVarKommenCol2 / 100

            
            // TotalEinkommenSum
            const TotEinkom = [
                Kun.FixkomKreI,
                Kun.FixkomKreII,
                Kun.CalcInputVarKomI,
                Kun.CalcInputVarKomII,
                Kun.NebkomKNI,
                Kun.NebkomKNII,
                Kun.bersLie,
            ]
            const convertTotEinkom = TotEinkom.map(parseFloat)
            const TotalEinkommenSum = convertTotEinkom.reduce((a, b) => a + b, 0)

            // TotBelastungSum
            const TotBel = [
                Kun.bersLieMinus,
                Kun.BelLeasings,
                Kun.Alimente,
            ]
            const convertTotBel = TotBel.map(parseFloat)
            const TotBelastungSum = convertTotBel.reduce((a, b) => a + b, 0)
            
            const computeVerEinkom = Kun.TotalEinkommen - Kun.TotBelastung
            
            // KundeGui.VerEinkom == KundeGui.VerEinkomcal ?dispatch({isSave:true}):dispatch({isSave:false})

            dispatch({KundeGui:{...KundeGui, CalcInputVarKomI:calcIVKCol1, CalcInputVarKomII:calcIVKCol2, TotalEinkommen:TotalEinkommenSum, TotBelastung:TotBelastungSum, VerEinkom:computeVerEinkom}})

        }else{
            setPercentAl(true)
        }
    }, [
        Kun.inputVarKommenCol1, 
        Kun.inputPercVarKommenCol1, 
        Kun.inputVarKommenCol2, 
        Kun.inputPercVarKommenCol2, 
        Kun.TotalEinkommen,
        Kun.FixkomKreI,
        Kun.FixkomKreII,
        Kun.CalcInputVarKomI,
        Kun.CalcInputVarKomII,
        Kun.NebkomKNI,
        Kun.NebkomKNII,
        Kun.bersLie,
        Kun.bersLieMinus,
        Kun.BelLeasings,
        Kun.Alimente,
        Kun.TotBelastung,
        Kun.VerEinkom
    ])
    const [FixkomI, setFixkomI] = useState(false)
    const [FixkomII, setFixkomII] = useState(false)
    const [FixkomI1, setFixkomI1] = useState(false)
    const [VarKommenCol2, setVarKommenCol2] = useState(false)
    const [Nebkom, setNebkom] = useState(false)
    const [NebkomKNII, setNebkomKNII] = useState(false)
    const [bersLie, setbersLie] = useState(false)
    const [bersLieMinus, setbersLieMinus] = useState(false)
    const [BelLe, setBelLe] = useState(false)
    const [Alime, setAlime] = useState(false)


    return (
        <>
            <div className="page-cform shadow mb-5">
                <div className="container">
                    <FormGroup row>
                        <Col sm="5"></Col>
                        <Col sm="3" className="text-center">
                            Borrower One
                        </Col>
                        <Col sm="3" className="text-center">
                            Borrower Two
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mt-4 text-center">
                        <Label className="text-left" for="Kundenname" sm={5}>Customer Name(n)</Label>
                        <Col sm="3">
                            {clientDetails[0].kundennameI}
                        </Col>
                        <Col sm="3">
                            {clientDetails[0].kundennameII}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="Jahrgang" sm={5}>Year of Birth</Label>
                        <Col sm="3">
                            <Input type="number" name="Jahrgang" 
                                value={KundeGui.JahrgangI}
                                onChange={(e) => {
                                    dispatch({KundeGui:{...KundeGui, JahrgangI:e.currentTarget.value}})
                                }}
                            />
                        </Col>
                        <Col sm="3">
                            <Input type="number" name="Jahrgang" 
                                value={KundeGui.JahrgangII}
                                onChange={(e) => {
                                    dispatch({KundeGui:{...KundeGui, JahrgangII:e.currentTarget.value}})
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="FixeinkommenKreditnehmerI" sm={5}>Fixed Income Borrower I</Label>
                        <Col sm="3">
                            <Input type="text" name="FixkomKreI" 
                                value={FixkomI? KundeGui.FixkomKreI:numeral(KundeGui.FixkomKreI).format("0,0").split(',').join("'")}
                                onFocus= {() => {setFixkomI(true)}}
                                onBlur = {() => {setFixkomI(false)}}
                                onChange={(e) => {
                                    evn(e, 'FixkomKreI')
                                }}
                            />
                        </Col>
                        <Col sm="3">
                            <Input type="text" name="FixkomKreCol2I" 
                                value={FixkomII?KundeGui.FixkomKreII:parseFloat(KundeGui.FixkomKreII).toLocaleString().split(',').join("'")}
                                onFocus= {() => {setFixkomII(true)}}
                                onBlur = {() => {setFixkomII(false)}}
                                onChange={(e) => {
                                    evn(e, 'FixkomKreII')
                                }}
                            />
                        </Col>
                        
                        <Label sm={5}>Variable Income Borrower</Label>
                        <Col sm="3" className="ta-4">
                            <label className="fl">Fr.</label> 
                            <b className="res-val-8">{ 
                                `${
                                    parseFloat(KundeGui.CalcInputVarKomI.toString().split(".")[0]).toLocaleString().split(',').join("'")}.${KundeGui.CalcInputVarKomI.toFixed(2).toString().split(".")[1]
                                }`
                            }
                            </b>
                        </Col>
                        <Col sm="3" className="ta-4">
                            <label className="fl">Fr.</label> 
                            <b className="res-val-8">{ 
                                `${
                                    parseFloat(KundeGui.CalcInputVarKomII.toString().split(".")[0]).toLocaleString().split(',').join("'")}.${KundeGui.CalcInputVarKomII.toFixed(2).toString().split(".")[1]
                                }`
                            }
                            </b>
                        </Col>
                                <Label for="inputVarKommen" sm={5}></Label>
                                <Col sm="3">
                                    <Input type="text" name="inputVarKommenI" 
                                        value={FixkomI1?KundeGui.setFixkomImenCol1:parseFloat(KundeGui.setFixkomImenCol1).toLocaleString().split(',').join("'")}
                                        placeholder="Bunus"
                                        onFocus= {() => {setFixkomI1(false)}}
                                        onBlur = {() => {setFixkomI1(false)}}
                                        onChange={(e) => {
                                            evn(e, 'inputVarKommenCol1')
                                        }}/>
                                </Col>
                                <Col sm="3">
                                    <Input type="text" name="inputVarKommenCol2I" 
                                        value={VarKommenCol2?KundeGui.inputVarKommenCol2:parseFloat(KundeGui.inputVarKommenCol2).toLocaleString().split(',').join("'")}
                                        placeholder="Bunus"
                                        onFocus= {() => {setVarKommenCol2(true)}}
                                        onBlur = {() => {setVarKommenCol2(false)}}     
                                        onChange={(e) => {
                                            evn(e, 'inputVarKommenCol2')
                                        }}
                                    />
                                </Col>
                                <Label for="inputVarKommen" sm={5}></Label>
                                <Col sm="3">
                                    <Input type="text" name="inputVarKommenI" 
                                        value={KundeGui.inputPercVarKommenCol1}
                                        placeholder="Percentage(%)"
                                        onChange={(e) => {
                                            evn(e, 'inputPercVarKommenCol1')
                                        }}
                                        style={{
                                            borderColor: percentAl && KundeGui.inputPercVarKommenCol1 > 50?
                                                '#f7255d66':'', 
                                            boxShadow: percentAl && KundeGui.inputPercVarKommenCol1 > 50? 
                                                '0 0 0 0.2rem rgba(241, 123, 123, 0.25)':''}}
                                    />
                                </Col>
                                <Col sm="3">
                                    <Input type="text" name="inputVarKommenII"  
                                        value={KundeGui.inputPercVarKommenCol2}
                                        placeholder="Percentage(%)"
                                        onChange={(e) => {
                                            evn(e, 'inputPercVarKommenCol2')
                                        }}
                                        style={{
                                            borderColor: percentAl && KundeGui.inputPercVarKommenCol2? 
                                                '#f7255d66':'', 
                                            boxShadow: percentAl && KundeGui.inputPercVarKommenCol2 > 50? 
                                                '0 0 0 0.2rem rgba(241, 123, 123, 0.25)':''}}
                                    />
                                    
                                </Col>
                                <Label for="inputVarKommen" sm={5}></Label>
                                <Col sm="6" className="text-center">
                                    <p style={{color: percentAl?'rgb(251, 74, 74)':'', display:percentAl?'initial':'none'}}>Please Input 50% below</p>
                                </Col>
                                
                        <Label for="NebeneinkommenKNI" sm={5}>Nebeneinkommen KN</Label>
                        <Col sm="3">
                            <Input type="text" name="NebkomKNI" 
                                value={Nebkom?KundeGui.NebkomKNI:parseFloat(KundeGui.NebkomKNI).toLocaleString().split(',').join("'")}
                                onFocus= {() => {setNebkom(true)}}
                                onBlur = {() => {setNebkom(false)}}
                                onChange={(e) => {  
                                    evn(e, 'NebkomKNI')
                                }}
                            />
                        </Col>
                        <Col sm="3">
                            <Input type="text" name="NebkomKNCol2I" 
                                value={NebkomKNII? KundeGui.NebkomKNII :parseFloat(KundeGui.NebkomKNII).toLocaleString().split(',').join("'")}
                                onFocus= {() => {setNebkomKNII(true)}}
                                onBlur = {() => {setNebkomKNII(false)}}
                                onChange={(e) => {
                                    evn(e, 'NebkomKNII')
                                }}
                            />
                        </Col>
                        
                        <Label for="bersLie" sm={5}>Surplus from Real Estate (+)</Label>
                        <Col sm="6">
                            <Input type="text" name="bersLie" 
                                value={bersLie?KundeGui.bersLie:parseFloat(KundeGui.bersLie).toLocaleString().split(',').join("'")}
                                onFocus= {() => {setbersLie(true)}}
                                onBlur = {() => {setbersLie(false)}}
                                onChange={(e) => {
                                    evn(e, 'bersLie')
                                }}
                            />
                        </Col>
                        <Label for="TotalEinkommen" sm={5}>Total Income</Label>
                        <Col sm="6" className="ta-4">
                            <label className="fl">Fr.</label> 
                            <b className="res-val-8">{ 
                                `${
                                    parseFloat(KundeGui.TotalEinkommen.toString().split(".")[0]).toLocaleString().split(',').join("'")}.${KundeGui.TotalEinkommen.toFixed(2).toString().split(".")[1]
                                }`
                            }
                            </b>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="bersLieMinus" sm={5}>Deficit from Real Estate (-)</Label>
                        <Col sm="6">
                            <Input type="text" name="bersLieMinus" 
                                value={bersLieMinus?KundeGui.bersLieMinus:parseFloat(KundeGui.bersLieMinus).toLocaleString().split(',').join("'")}
                                onFocus={() => {setbersLieMinus(true)}}
                                onBlur = {() => {setbersLieMinus(false)}}
                                onChange={(e) => {
                                    evn(e, 'bersLieMinus')
                                }}
                            />
                        </Col>

                        <Label for="BelLeasings" sm={5}>Liability from Leasings</Label>
                        <Col sm="6">
                            <Input type="text" name="BelLeasings" 
                                value={BelLe?KundeGui.BelLeasings:parseFloat(KundeGui.BelLeasings).toLocaleString().split(',').join("'")}
                                onFocus= {() => {setBelLe(true)}}
                                onBlur = {() => {setBelLe(false)}}
                                onChange={(e) => {
                                    evn(e, 'BelLeasings')
                                }}
                            />
                        </Col>

                        <Label for="Alimente" sm={5}>Alimony or similar</Label>
                        <Col sm="6">
                            <Input type="text" name="Alimente" 
                                value={Alime?KundeGui.Alimente:parseFloat(KundeGui.Alimente).toLocaleString().split(',').join("'")}
                                onFocus= {() => {setAlime(true)}}
                                onBlur = {() => {setAlime(false)}}
                                onChange={(e) => {
                                    evn(e, 'Alimente')
                                }}
                            />
                        </Col>

                        <Label for="Alimente" sm={5}>Total Liability</Label>
                        <Col sm="6" className="ta-4">
                            <label className="fl">Fr.</label> 
                            <b className="res-val-8">{ 
                                `${
                                    parseFloat(KundeGui.TotBelastung.toString().split(".")[0]).toLocaleString().split(',').join("'")}.${KundeGui.TotBelastung.toFixed(2).toString().split(".")[1]
                                }`
                            }
                            </b>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="VerEinkom" sm={5}>Disposable Income</Label>
                        <Col sm="6" className="ta-4">
                            <label className="fl">Fr.</label> 
                            <b className="res-val-8">{ 
                                `${
                                    parseFloat(KundeGui.VerEinkom.toString().split(".")[0]).toLocaleString().split(',').join("'")}.${parseFloat(KundeGui.VerEinkom).toFixed(2).toString().split(".")[1]
                                }`
                            }
                            </b>
                        </Col>
                    </FormGroup>
                </div>
            </div>
        </>
    )
}

export default CustomerInfo
